import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Button, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function Organisation() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Organisation';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Organisation
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/organisation.jpg"}
                                title="Organisation"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    L’organisation d’une personne est caractérisée à l’ensemble des méthodes et des procédures qu’elle va utiliser 
                                    pour ordonner et classer son travail, un projet et la priorisation d’une tâche.
                                    <br />
                                    Avec la démocratisation du télétravail, l’organisation personnelle d’une personne est fortement mise à contribution. 
                                    Éloigné du lieu de travail habituel, le salarié peut facilement se retrouver déstabilisé et perdu pour la réalisation 
                                    une mission ou d’un projet.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Lors de l’organisation de la 
                                        {" "}
                                            <Link className={classes.link} to="/participationEvenement">« Journée des motards »</Link>
                                    , nous avons dû mettre en place un outil qui permettait 
                                    aux participants de se préinscrire à l’événement. Une fois le participant préinscrit, nous devions lui envoyer 
                                    un email pour qu’il complète son inscription. Régulièrement, le commanditaire de ce projet nous envoyait des 
                                    inscriptions d’autres participants. Nous avons donc mis en place des règles pour avoir la liste des participants 
                                    à jour régulièrement.
                                    <br />
                                    <br />
                                    En amont de l’événement, nous avons évalué, classifié et préparé les lots pour faciliter la distribution et pour 
                                    limiter la durée que celle-ci aller prendre. Le jour de l’événement, notre planning était clair. Dans un premier 
                                    temps, nous nous sommes occupés de l’accueil des participants et nous nous sommes mis à leurs dispositions en cas 
                                    des questions ou de soucis. Puis, nous avons collecté les tickets repas des participants, ceux-ci ont été mis en 
                                    place pour éviter un fort afflux de participants en même temps. L’après-midi, nous nous sommes concentrés sur la 
                                    correction du quiz et tout en étant à disposition des participants en cas de besoin. En fin de journée, nous avons 
                                    distribué les lots. Nous nous sommes organisés de tel sort que deux personnes allaient chercher les lots gagnés et 
                                    une autre annonçait le gagnant.
                                    <br />
                                    <br />
                                    Tout au long de ce projet, nous avons utilisé notre compétence organisationnelle, que cela soit pour les inscriptions, 
                                    la répartition des lots ou le jour de l’événement.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    L’organisation est une compétence que je maîtrise bien. Grâce à mes différentes expériences personnelles et 
                                    professionnelles, j’ai pu développer et améliorer mes méthodes d’organisation. Cette compétence est essentielle pour mon métier, 
                                    car sans elle, un projet ne pourrait pas évoluer de la meilleure des façons. 
                                    <br />
                                    Cependant, c’est avec l’expérience qu’on perfectionne cette compétence, je vais donc continuer à travailler dessus. Mon 
                                    conseil serait de toujours redécouper des tâches en petites actions pour faciliter sa concrétisation et donc d’avoir la 
                                    satisfaction de terminer quelque chose.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    L’organisation est une compétence essentielle dans la vie personnelle et professionnelle, elle est surtout indispensable 
                                    lorsque l’on fait de la gestion de projet ou pour mener à bien une mission. Cependant, il ne faut pas qu’elle devienne un 
                                    frein dans la réalisation d’un projet.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/refonteAppliDeSuivi" clickable color="primary" label="Refonte application suivi de production" variant="outlined" />
                                <Chip component={Link} to="/participationEvenement" clickable color="primary" label="Participation à un événement" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}