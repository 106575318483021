import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Button, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function Communication() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Communication';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Communication
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/communication.jpg"}
                                title="Communication"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    La communication est la capacité à échanger et à transmettre une information à différentes personnes. 
                                    Cette communication peut se faire aussi bien par écrit que par oral. 
                                    <br />
                                    Dans une entreprise, la communication peut prendre diverses formes, par exemple entre les membres d’une 
                                    équipe ou entre un chef de projet et des clients.
                                    <br />
                                    Avec la crise sanitaire, la communication a évolué de même que les supports de celle-ci qui sont devenus 
                                    de plus en plus dématérialisés et numériques.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Lors de ma formation à INTECH, nous avons appris à communiquer, que cela soit pour une communication interne, 
                                    entre les membres d’un groupe, ou une communication externe, avec un commanditaire ou lors de publication sur les réseaux.
                                    <br />
                                    <br />
                                    Lors de ma participation à l’organisation d’un événement, j’ai eu l’opportunité de promouvoir la 
                                    {" "}
                                        <Link className={classes.link} to="/participationEvenement">« Journée des motards »</Link>
                                    {" "}
                                    ainsi que l’école à la radio et sur les réseaux sociaux. Nous avons été invités à la radio 47FM et Radio Bulle 
                                    pour présenter l’événement, le but de celui-ci et de décrire les différentes activités qu’il y aura. Par la 
                                    suite, nous avons présenté l’école INTECH, les différentes formations et expliqué notre implication à l’événement 
                                    la « Journée des motards ». Nous avons aussi mis en place toute une campagne de promotion sur les réseaux sociaux 
                                    et dans la presse locale. Les diverses actions ont permis de toucher un plus grand public.
                                    <br />
                                    <br />
                                    Lors de ma mission de développement de l’
                                        <Link className={classes.link} to="/appliDeSuivi">application de suivi de production</Link>
                                    {" "}
                                    ainsi que sa maintenance, j’ai dû échanger 
                                    avec les opérateurs de production, la cheffe d’atelier de même qu’avec le SAV. Ces échanges m’ont permis de comprendre 
                                    bien leurs besoins et leurs attentes pour la nouvelle version de l’application. Puis à chaque étape importante dans le 
                                    développement de l’outil, une démonstration était faite pour recueillir des impressions et les idées d’amélioration de chacun.
                                    Ces différents échanges ont été indispensables pour le bon déroulement et la réussite du projet.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    Étant une personne introvertie, la communication avec les autres peut parfois être difficile. Cependant, je me rends 
                                    compte que depuis que je suis en alternance, j’ai de plus en plus de facilité échanger avec mes collègues. 
                                    La communication est une compétence indispensable dans la vie professionnelle, que ce soit pour échanger avec 
                                    le client ou avec ses collègues. Pour cela, je vais poursuivre mes efforts pour m’ouvrir aux autres et interagir avec eux. 
                                    <br />
                                    De mon expérience professionnelle, je conseillerais que la clé de la communication est de s’entraîner, de tester 
                                    pour s’améliorer et réussir s’épanouir dans une équipe. 
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    La communication est essentielle et nécessaire dans la vie de tous les jours ainsi que dans la vie professionnelle. 
                                    Un projet ne peut exister ni avancer si les personnes impliquées ne communiquent pas entre elles. C’est pour cela que 
                                    je vais continuer mes efforts pour m’améliorer, car si cette aptitude n’est pas assez mise en œuvre, elle peut être 
                                    un frein dans une entreprise.  
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/appliDeSuivi" clickable color="primary" label="Application suivi de production" variant="outlined" />
                                <Chip component={Link} to="/refonteAppliDeSuivi" clickable color="primary" label="Refonte application suivi de production" variant="outlined" />
                                <Chip component={Link} to="/actionDeFormation" clickable color="primary" label="Action de formation" variant="outlined" />
                                <Chip component={Link} to="/participationEvenement" clickable color="primary" label="Participation à un évènement" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}