import React, { useEffect } from "react";
import TemplateCard from './TemplateCard';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    btn: {
        margin: theme.spacing(0, 2, 2, 0),
    }
}));

export default function Skill() {
    const classes = useStyles();
    const [value, setValue] = React.useState("all");
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        document.title = 'Mes compétences';
        window.scrollTo(0, 0);
    });
    
    const humainCards = [ 
        {
            id: 4, 
            image: process.env.PUBLIC_URL + "/organisation.jpg",
            title: "Organisation",
            stars: 5,
            describe: "L’organisation d’une personne est caractérisée à l’ensemble des méthodes et des procédures qu’elle va utiliser pour ordonner et classer son travail, un projet et la priorisation d’une tâche.",
            page: "/organisation",
            skill: [
                {
                    nameSkill: "Refonte application suivi de production",
                    pageSkill: "/refonteAppliDeSuivi"  
                },
                {
                    nameSkill: "Participation à un évènement",
                    pageSkill: "/participationEvenement"  
                }
            ]
        },  
        {
            id: 6, 
            image: process.env.PUBLIC_URL + "/peda.jpg",
            title: "Pédagogie",
            stars: 4,
            describe: "La pédagogie désigne l'art d'enseigner, de former et de transmettre à une ou plusieurs personnes. Ce terme rassemble les méthodes et pratiques d'enseignement requises pour transmettre un savoir, un savoir-faire et un savoir-être.",
            page: "/pedagogie",
            skill: [
                {
                    nameSkill: "Action De Formation",
                    pageSkill: "/actionDeFormation" 
                }
            ]
        },
        {
            id: 8, 
            image: process.env.PUBLIC_URL + "/espritEquipe2.jpg",
            title: "Travail d'équipe",
            stars: 4,
            describe: "Le travail en équipe est un concept dans lequel des personnes vont interagir et s’organiser entre elles. Chaque membre de l'équipe doit œuvrer non pas individuellement, mais avec tous les autres pour atteindre un objectif commun.",
            page: "/travailEquipe",
            skill: [
                {
                    nameSkill: "Participation à un évènement",
                    pageSkill: "/participationEvenement" 
                }
            ]
        }, 
        {
            id: 3, 
            image: process.env.PUBLIC_URL + "/communication.jpg",
            title: "Communication",
            stars: 3,
            describe: "La communication est la capacité à échanger et à transmettre une information à différentes personnes. Cette communication peut se faire aussi bien par écrit que par oral.",
            page: "/communication",
            skill: [
                {
                    nameSkill: "Application suivi de production",
                    pageSkill: "/appliDeSuivi" 
                },
                {
                    nameSkill: "Refonte application suivi de production",
                    pageSkill: "/refonteAppliDeSuivi"  
                },
                {
                    nameSkill: "Action De Formation",
                    pageSkill: "/actionDeFormation"  
                },
                {
                    nameSkill: "Participation à un évènement",
                    pageSkill: "/participationEvenement"  
                }
            ]
        },  
        {
            id: 1, 
            image: process.env.PUBLIC_URL + "/autonomie2.jpg",
            title: "Autonomie",
            stars: 4,
            describe: "L’autonomie est la capacité s’occuper de la résolution d’un problème ou d’une tâche en étant seul. Être autonome, c’est aussi savoir solliciter de l’aide ou bien demander des conseils lorsque l’on rencontre des problèmes pour l’aboutissement du projet ou de la tâche.",
            page: "/autonomie",
            skill: [
                {
                    nameSkill: "Application mobile",
                    pageSkill: "/appliMobile" 
                }
            ]
        }, 
    ];
    const technicalCards = [  
        {
            id: 10, 
            image: process.env.PUBLIC_URL + "/ima_php.jpg",
            title: "Php",
            stars: 5,
            describe: "PHP (Hypertext Preprocessor) est un langage de scripts généraliste et Open Source, spécialement conçu pour le développement d'applications web dynamiques.",
            page: "/php",
            skill: [
                {
                    nameSkill: "Application suivi de production",
                    pageSkill: "/appliDeSuivi" 
                }
            ]
        }, 
        {
            id: 7, 
            image: process.env.PUBLIC_URL + "/sql2.jpg",
            title: "SQL",
            stars: 4,
            describe: "SQL (Structured Query Language) est un langage servant à exploiter des bases de données relationnelles. SQL permet de rechercher, d'ajouter, de modifier ou de supprimer des données dans les bases de données relationnelles.",
            page: "/sql",
            skill: [
                {
                    nameSkill: "Refonte application suivi de production",
                    pageSkill: "/refonteAppliDeSuivi" 
                },
                {
                    nameSkill: "Application suivi de production",
                    pageSkill: "/appliDeSuivi" 
                }
            ]
        }, 
        {
            id: 2, 
            image: process.env.PUBLIC_URL + "/ima_bis.jpg",
            title: "React",
            stars: 4,
            describe: "React est une bibliothèque JavaScript libre permettant de créer des interfaces utilisateurs interactives. React facilite la création d'application web du type « single page » et la séparer la partie back-end et front-end.",
            page: "/react",
            skill: [
                {
                    nameSkill: "Refonte application suivi de production",
                    pageSkill: "/refonteAppliDeSuivi" 
                },
                {
                    nameSkill: "Application suivi de production",
                    pageSkill: "/appliDeSuivi" 
                }
            ]
        }, 
        {
            id: 5, 
            image: process.env.PUBLIC_URL + "/android3.jpg",
            title: "Java",
            stars: 4,
            describe: "Java est un langage de programmation orienté objet. Il permet de créer des logiciels pour les ordinateurs. Java donne aussi la possibilité de développer des applications pour téléphones, assistants personnels ou encore pour le web.",
            page: "/java",
            skill: [
                {
                    nameSkill: "Application mobile",
                    pageSkill: "/appliMobile" 
                }
            ]
        },
        {
            id: 9, 
            image: process.env.PUBLIC_URL + "/git.jpg",
            title: "Git",
            stars: 3,
            describe: "Git est un logiciel de gestion de versions de fichiers décentralisé. Git va faire un historique des différents fichiers d’un projet et permet de stocker le projet ailleurs que sur la machine de l’utilisateur.",
            page: "/git",
            skill: [
                {
                    nameSkill: "Application mobile",
                    pageSkill: "/appliMobile" 
                }
            ]
        },
    ];

    const buttons = [
        {
            name: "all",
            value:"Compétences humaines & techniques"
        },
        {
            name: "humain",
            value: "Compétences humaines"},
        {
            name: "technical",
            value: "Compétences techniques"},
    ];

    return (
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Mes Compétences
                </Typography>
            </Container>
            <Container className={classes.cardGrid} maxWidth="lg">
                {
                    buttons.map((txt, i) => (
                        <Button 
                            className={classes.btn}
                            key={i}
                            variant={(value === txt.name) ? "contained" : "outlined"} 
                            color="primary" 
                            onClick={e => handleChange(e, txt.name)}
                        >
                            {txt.value}
                        </Button>
                    ))
                }
                {
                    value === "all" && (
                        <Grid container spacing={4}>
                            {
                                humainCards.map((card) => (
                                    <TemplateCard key={card.id} card={card}/>
                                ))
                            }
                            {
                                technicalCards.map((card) => (
                                    <TemplateCard key={card.id} card={card}/>
                                ))
                            }
                        </Grid>
                    )
                }
                {
                    value === "humain" && (
                        <Grid container spacing={4}>
                            {
                                humainCards.map((card) => (
                                    <TemplateCard key={card.id} card={card}/>
                                ))
                            }
                        </Grid>
                    )
                }
                {
                    value === "technical" && (
                        <Grid container spacing={4}>
                            {
                                technicalCards.map((card) => (
                                    <TemplateCard key={card.id} card={card}/>
                                ))
                            }
                        </Grid>
                    )
                }
            </Container>
        </div>
    );
}