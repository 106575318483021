import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    gridImg: {
        padding: theme.spacing(2, 0, 0, 0),
    },
    caption: {
        padding: theme.spacing(0, 0, 2, 0),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function ParticipationEvenement() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Participation à un événement';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Participation à un événement
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/journeeMotard2.png"}
                                title="Participation à un événement"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    Projet réalisé lors de ma première année à l’école INTECH. La mission était de participer à l’organisation de la 
                                    « Journée des motards », qui est un moment de partage, de rencontre, d’approfondissement des techniques de conduite et de 
                                    sensibilisation.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    L’Escadron Départemental de la Sécurité Routière (EDSR) du Lot-et-Garonne organise tous les ans la « Journée des motards ». 
                                    Cet événement a pour but de sensibiliser les usages, nouveaux ou non à la sécurité routière. À travers différents ateliers, 
                                    les objectifs de l’EDSR sont d’améliorer leur technique de conduite sur deux-roues, de se familiariser avec la réglementation, 
                                    de participer à l’étude et à la recherche d’un itinéraire donné le tout en vivant une journée de rencontre, de convivialité et 
                                    de plein air.
                                    <br />
                                    <br />
                                    Le gendarme en charge (commanditaire) de cet événement a demandé de l’aide pour la seconde édition de la « Journée des motards ».
                                    <br />
                                    Mon groupe et moi-même avions pour objectif de concevoir un outil afin de recenser les préinscriptions des participants et 
                                    de gérer les inscriptions pour le déjeuner. De plus, nous devions définir les conditions de réussite aux ateliers pour la 
                                    distribution des lots et d’accueillir les participants lors de leur arrivée et de les guider tout le long de la journée.
                                    <br />
                                    <br />
                                    Le commanditaire a fait appel aux étudiants de l’école pour la première fois. S’il était satisfait de cette collaboration 
                                    alors le commanditaire renouvellerait l’expérience pour les éditions suivantes.
                                    <br />
                                    Outre le fait de gêner plus qu’aider lors de l’événement, nous devions faire attention que la remise des lots ne devait pas 
                                    être trop longue, car celle-ci se déroulait en fin de journée et que les participants souhaitaient partir.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Déroulé
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Le commanditaire avait besoin d’un outil permettant aux candidats de se préinscrire succinctement à la « Journée des motards ». 
                                    Nous lui avons donc proposé d’utiliser un Google Form (générateur de formulaire en ligne) et de déterminer une date butoir de 
                                    fin de pré-inscription, pour une meilleure gestion des candidatures. Le lien a alors été rajouté à l’affiche réalisée par 
                                    l’école CAGO. Nous avons participé à la campagne de 
                                    {" "}
                                        <Link className={classes.link} to="/communication">communication</Link>
                                    {" "}
                                    en parlant de la « Journée des motards » sur différentes radios 
                                    telles que Radio Bulle, 47 FM et en partageant sur les réseaux sociaux des posts et l’affiche de l’événement.
                                </Typography>
                                <Grid alignItems="center" className={classes.gridImg} justify="center" container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/radio47FM.JPG"} alt="Emission radio sur 47FM" width="100%" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/afficheJourneeDesMotards.jpg"} alt="Affiche de la Journée des motards" width="100%" />
                                    </Grid>
                                    <Grid align="center" item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/radioBulle.JPG"} alt="Emission radion sur radio bulle" width="100%" />
                                    </Grid>
                                </Grid>
                                <div className={classes.caption} align="center">
                                    <Typography variant="subtitle1">Photos des émissions radios et affiche de l'événement</Typography>
                                </div>
                                <Typography align="justify">
                                    Le commanditaire a reçu des lots de la part des sponsors à donner aux participants. Cependant, il n’a pas souhaité que les 
                                    lots soient gagnés de façon compétitive lors des différentes activités de la journée. Pour cela, nous avons décidé de créer 
                                    un questionnaire à choix multiples (QCM) sur le monde de la moto. Après s’être tous concertés, nous avons choisi des questions 
                                    sur divers thèmes : technique, général, sportif, …
                                </Typography>
                                <Grid alignItems="center" className={classes.gridImg} justify="center" container spacing={2}>
                                    <Grid align="center" item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/QCM.png"} alt="Questionnaire à choix multiples" width="100%" />
                                    </Grid>
                                </Grid>
                                <div className={classes.caption} align="center">
                                    <Typography variant="subtitle1">Photo du questionnaire à choix multiples donné aux participants</Typography>
                                </div>
                                <Typography align="justify">
                                    Un autre de nos objectifs était la gestion des déjeuners pour les participants. Afin de connaître, au plus juste, le nombre de 
                                    repas à servir, nous avons mis en place un système de ticket-repas pour le déjeuner. Nous avons aussi créé un questionnaire de 
                                    satisfaction pour avoir les retours des participants sur les activités proposées, la communication mise en place et sur ce qu’ils 
                                    voudraient comme améliorations pour les prochaines éditions de l’événement.
                                </Typography>
                                <Grid alignItems="center" className={classes.gridImg} justify="center" container spacing={2}>
                                    <Grid align="center" item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/preparationPoches.png"} alt="Préparation des poches pour les participants" width="100%" />
                                    </Grid>
                                    <Grid align="center" item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/poches.JPG"} alt="L'ensembles des poches" width="75%" />
                                    </Grid>
                                </Grid>
                                <div className={classes.caption} align="center">
                                    <Typography variant="subtitle1">Photos de la préparation des poches distribuées aux participants</Typography>
                                </div>
                                <Typography align="justify">
                                    Pour être opérationnel dès l’arrivée des participants, nous avons décidé avec le commanditaire de distribuer des poches pour 
                                    chaque motard. Ces poches contenaient un gilet jaune, le QCM, le questionnaire de satisfaction, les tickets-repas, le dossard et 
                                    un autocollant de l’événement. De plus, nous avons géré la constitution les groupes de participants en fonction des affinités de 
                                    ceux-ci. 
                                </Typography>
                                <Typography align="justify">
                                    Lors de l’événement, nous avons accueilli environ 150 participants (conducteurs et passagers). Notre rôle a été de leur distribuer 
                                    les poches et d’orienter les motards.
                                    <br />
                                    <br />
                                    Les participants avaient jusqu’au début des ateliers de l’après-midi pour nous rendre le QCM. L’étape la plus difficile fut de 
                                    corriger les 84 questionnaires de façon minutieuse et dans un temps réduit. Puis, d’effectuer la répartition des cadeaux offerts 
                                    par de multiples sponsors. Puisqu’il y avait, en effet, plus de lots que de participants.
                                    <br />
                                    <br />
                                    Malgré tout, nous avons pu gérer ces aléas avec efficacité, ce qui nous a valu une reconnaissance très positive de notre travail 
                                    par le commanditaire.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Résultat
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Grâce aux questionnaires de satisfaction complétés par les participants, nous avons pu faire un point avec le commanditaire 
                                    sur les améliorations possibles, les ateliers à conserver, le ressenti des participants. Ces différents retours permettront de 
                                    perfectionner les prochaines éditions de la « Journée des motards ».
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Ce projet m'a appris à gérer un événement dans un domaine atypique qui était la sécurité routière et le monde de la moto. 
                                    Il m'a également apporté de nombreuses compétences dans la communication et le relationnel, que cela soit entre les membres 
                                    du groupe ou avec les personnes gravitant autour de l’événement.
                                    <br />
                                    <br />
                                    Nous avons prouvé que nous étions efficaces et fiables pour gérer et aider lors d’un tel événement. De plus, étant très satisfait 
                                    du travail que nous avons accompli, le commanditaire a décidé que continuer le partenariat à l’école. L’année suivante, un nouveau 
                                    groupe d’étudiants a repris le projet.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/organisation" clickable color="primary" label="Organisation" variant="outlined" />
                                <Chip component={Link} to="/communication" clickable color="primary" label="Communication" variant="outlined" />
                                <Chip component={Link} to="/travailEquipe" clickable color="primary" label="Travail d'équipe" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}