import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Divider, Grid, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function SkillReact() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'React';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    React
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/ima_bis.jpg"}
                                title="React"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    React est une 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Biblioth%C3%A8que_logicielle" target="bang">
                                        bibliothèque
                                    </a> 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/JavaScript" target="bang">
                                        JavaScript
                                    </a> 
                                    {" "}
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Logiciel_libre" target="bang">
                                        libre
                                    </a> 
                                    {" "} 
                                    libre permettant de créer des interfaces utilisateurs interactives. 
                                    React facilite la création d'application web du type «
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Application_web_monopage" target="bang">
                                        single page
                                    </a> 
                                    » et la séparation de la partie
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Backend" target="bang">
                                        back-end
                                    </a>
                                    {" "}
                                     et 
                                    {" "}
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/D%C3%A9veloppement_web_frontal" target="bang">
                                        front-end
                                    </a>. 
                                    Cette bibliothèque utilise des composants possédant un état et lorsque celui-ci est modifié, cela va générer un 
                                    rafraîchissement d'une partie de l'interface utilisateur et non pas le rechargement de la totalité de la page 
                                    comme l’on peut trouver sur la majorité des sites internet.
                                    <br />
                                    En 2020, React était l’une des bibliothèques les plus utilisées et la plus appréciée par les développeurs
                                    {" "}
                                    <a className={classes.link} href="https://insights.stackoverflow.com/survey/2020#technology-most-loved-dreaded-and-wanted-web-frameworks-loved2" target="bang">
                                        (source)
                                    </a>.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    React est une bibliothèque que j'ai découverte et utilisée lors du projet de 
                                    {" "}
                                        <Link className={classes.link} to="/refonteAppliDeSuivi">refonte de l'application de suivi</Link>
                                    , lors de ma deuxième année d'alternance à Fonroche Lighting.
                                    <br />
                                    La décision étant prise d'homogénéiser les technologies de toutes les applications web développées en 
                                    interne, la décision s'est donc portée sur React. Cette technologie a été choisie, car elle est très 
                                    appréciée et a une forte communauté. Celle-ci est dorénavant une technologie clé pour le développement 
                                    des applications web dans l’entreprise.
                                    <br />
                                    Comme dit précédemment, j’ai découvert React pour la réalisation d’un projet et je n’ai aucune connaissance 
                                    de cette technologie. Je me suis donc auto formée à l’aide de la documentation disponible sur le site de 
                                    React ainsi qu’avec l’aide d’un de mes collègues qui avait déjà utilisé React. Après plusieurs mois de 
                                    développement, l’application de suivi de production possède des fonctionnalités développées avec React 
                                    comme la création d’un template pour une référence d’un produit ou l’ajout, dans la base de données, 
                                    d’une nouvelle version du programme qui sera inséré dans les produits. Le fait d’avoir développé ces 
                                    fonctionnalités en React a permis une meilleure fluidité pour l’affichage des données ou le contrôle 
                                    des erreurs.
                                    <br />
                                    <br />
                                    J’ai aussi utilisé cette technologie pour la création de ce portfolio. Le fait de faire ce projet avec 
                                    React m’a permis de vraiment prendre en main cette technologie. En plus, de me permettre de mettre en 
                                    place des fonctionnalités que je n’ai pas eu l’occasion de faire sur le projet de la refonte de 
                                    l’application de production et inversement.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    Voilà plusieurs mois que je travaille sur le projet de refonte de l'application de suivi de production en React, 
                                    je pense donc que j'ai assimilé le fonctionnement de React. Je trouve que cette technologie est simple à prendre 
                                    en main et son fonctionnement très intuitif. De plus, je pense avoir une bonne maîtrise de React, mais s’il y a 
                                    beaucoup d'outils qui gravitent autour, dont j'ai moins de connaissance, peuvent s'ajouter à celle-ci. Comme dit 
                                    précédemment, React est une compétence importante pour mon projet professionnel, car si je souhaite m’orienter 
                                    vers du développement web, il y a de fortes chances que cette compétence soit demandée. Si j’avais un conseil à 
                                    donner, il serait celui-ci : d’avoir une bonne maîtrise du langage JavaScript, car React repose sur ce langage.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    J'ai beaucoup apprécié de travailler avec la bibliothèque React, car en plus de sa simplicité d'utilisation 
                                    et de sa mise en place, cette technologie a un fort avenir et va prendre de plus en plus de place dans le 
                                    monde des applications web.
                                    <br />
                                    J’ai conscience qu’il me reste encore beaucoup à apprendre sur cette technologie. Et malgré sa dernière version, 
                                    sorti fin d’année dernière, elle continue a été très populaire auprès des développeurs web et est de plus en 
                                    plus utilisée dans le monde. C’est pour cela que j’envisage de continuer à utiliser cette technologie et a 
                                    continué à me former dessus.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/appliDeSuivi" clickable color="primary" label="Application suivi de production" variant="outlined" />
                                <Chip component={Link} to="/refonteAppliDeSuivi" clickable color="primary" label="Refonte application suivi de production" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}