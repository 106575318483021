import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Button, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function Sql() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'SQL';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    SQL
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/sql2.jpg"}
                                title="SQL"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    SQL (Structured Query Language) est un langage servant à exploiter des 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Base_de_donn%C3%A9es_relationnelle" target="bang">
                                        bases de données relationnelles
                                    </a> 
                                    {" "} 
                                   . 
                                    SQL permet de rechercher, d'ajouter, de modifier ou de supprimer des données dans les bases de données relationnelles.
                                    <br />
                                    De nombreuses bases de données s'appuient sur le langage SQL et il représente l’un des langages les plus utilisé 
                                    par les développeurs.
                                    <br />
                                    Dans l’entreprise où j’ai réalisé mon stage et mon alternance, la totalité des applications ont utilisé le langage 
                                    SQL pour communiquer avec les bases de données.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Récurrent dans tous les projets informatiques réalisés à INTECH ou dans un contexte professionnel, quel que soit le 
                                    type d’application, web ou mobile, SQL est un langage que j’utilise régulièrement. 
                                    <br />
                                    <br />
                                    Lors de mon parcours professionnel, j’ai utilisé SQL pour le stockage et la récupération des informations dans la base 
                                    de données. J’ai utilisé ce langage pour l’
                                        <Link className={classes.link} to="/appliDeSuivi">application de suivi de production</Link>
                                        ,
                                        {" "}
                                        <Link className={classes.link} to="/refonteAppliDeSuivi">sa refonte</Link>
                                        {" "}
                                        et l’
                                        <Link className={classes.link} to="/appliMobile">application mobile</Link>
                                        . 
                                    <br />
                                    Lors du développement de l’application de suivi de production, j’ai créé une fonctionnalité de recherche. Cette 
                                    fonctionnalité permet à un utilisateur de faire une recherche classique ou une recherche avancée.
                                    <br />
                                    Une recherche dite classique va s’effectuer via un numéro de série d’un produit fabriqué ou d’un composant. 
                                    Alors qu’une recherche avancée va s’effectuer grâce à l’ajout de filtre tel que la date de fabrication du produit, 
                                    la commande qui est rattachée au produit, le client ou encore le fournisseur d’un composant.
                                    <br />
                                    La particularité de cette fonctionnalité est que toutes les informations qu’elle doit renvoyer à l’utilisateur 
                                    dépendent fortement du degré de précision que l’on souhaite. 
                                    <br />
                                    Je devais dont prendre en compte le fait que moins le critère sur le filtre était précis plus le temps de traitement 
                                    de la recherche était long. Pour réduire ces délais, j’ai dû réfléchir aux optimisations qui pouvaient être mises en place.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    Je pense avoir une bonne maîtrise du langage, mais si je n’utilise pas au quotidien toutes les fonctionnalités 
                                    qu’offre SQL tel que les triggers, les procédures ou les vues.
                                    <br />
                                    Que cela soit un logiciel, une application web ou mobile, la quasi-totalité utilise une base de données pour 
                                    stocker les informations récoltées. De plus, si l’on souhaite manipuler des informations, il y a de fortes 
                                    chances que SQL soit la technologie utilisée.  Cette compétence est donc indispensable pour mon avenir professionnel. 
                                    Au quotidien, je serai amené à utiliser cette technologie pour stocker, récupérer ou mettre à jour des données.
                                    <br />
                                    SQL est un langage dont les fondamentaux sont simples et rapides à apprendre. Il est surtout indispensable si 
                                    on veut interagir avec une base de données. Je conseillerai à toute nouvelle personne ne connaissant pas SQL de 
                                    se concentraient sur les fondamentaux et une fois cette partie-là bien maîtrisée d’augmenter progressivement la difficulté.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    SQL est un langage primordial pour un développeur, car la majorité des applications utilisent des bases de données 
                                    relationnelles. C’est pour cela que cette compétence ne doit pas être négligée et doit être continuellement améliorée.
                                    Pour cela, je souhaite améliorer, en autoformant, cette compétence pour avoir un niveau plus avancé et fournir des 
                                    applications avec des interactions avec la base de données optimiser dès le commencement du projet. 
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/appliDeSuivi" clickable color="primary" label="Application suivi de production" variant="outlined" />
                                <Chip component={Link} to="/refonteAppliDeSuivi" clickable color="primary" label="Refonte application suivi de production" variant="outlined" />
                                <Chip component={Link} to="/appliMobile" clickable color="primary" label="Application mobile" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}