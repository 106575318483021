import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function RefonteAppliDeSuivi() {
    const classes = useStyles();
    useEffect(() => {
        document.title = "Refonte de l'application de suivi de production";
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Refonte de l'application de suivi de production
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/refonteAppliDeSuivi.jpg"}
                                title="Refonte de l'application de suivi de production"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    Projet réalisé lors de ma deuxième année d'alternance chez Fonroche Lighting. Ma mission est d'améliorer et d'optimiser 
                                    l'application de suivi de production réalisé pendant mon stage.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    La refonte de l’application de suivi de production s’inscrit dans une démarche générale de redéveloppement des outils 
                                    fait en interne. Cette démarche a été initiée dans l’optique de connaître l’histoire du lampadaire depuis la première 
                                    prise de contact d’un prospect, en passant par la fabrication et l’installation du lampadaire jusqu’à la fin d’exploitation 
                                    de celui-ci.
                                    <br />
                                    <br />
                                    Ma mission était de redévelopper l’
                                            <Link className={classes.link} to="/appliDeSuivi">application de suivi de production</Link>
                                    , dans un nouveau langage, 
                                        {" "}
                                            <Link className={classes.link} to="/react">React</Link>
                                        {" "}
                                    , en prenant en compte les nouveaux besoins des opérateurs de la production, du service 
                                    des opérations ainsi que de l’entreprise.
                                    <br />
                                    <br />
                                    De cette mission en découlé plusieurs objectifs. Dans un premier temps, je devais fiabiliser le processus de fabrication 
                                    d’un lampadaire. Lors de l’assemblage de celui-ci, l’application ne faisait que peu de vérification sur les entrées faites 
                                    par les utilisateurs. Un utilisateur pouvait donc trouver des données complètement erronées pour un produit.
                                    <br />
                                    Un autre de mes objectifs était de simplifier l’interface utilisateur lors de la fabrication du lampadaire. Le but de cette 
                                    simplification étant de détecter les erreurs le plus tôt possible et de limiter les erreurs possibles. Enfin, une partie 
                                    pour le SAV devait été ajouté à l’application.
                                    <br />
                                    <br />
                                    Le principal enjeu de cette mission était la limitation des erreurs possibles par les différents utilisateurs pour 
                                    certifier la véracité des données collectées, que cela soit lorsque l’utilisateur renseigne les différents composants 
                                    constituant un produit ou la présence de composant non désiré dans la liste d’assemblage d’un produit.
                                    <br />
                                    <br />
                                    La mission étant de redévelopper l’application, le risque était que cette nouvelle version ne contienne des bogues et 
                                    empêche la production des lampadaires. De plus, l’application ne devait pas régresser, c’est-à-dire que les 
                                    fonctionnalités présentes sur la nouvelle version soient moins abouties ou qu’elles aient disparues par rapport à l’ancienne.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Déroulé
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    J’ai commencé ce projet par une phase d'avant-projet. J’ai d’abord 
                                        {" "}
                                            <Link className={classes.link} to="/communication">recueilli les besoins</Link>
                                        {" "} 
                                    des opérateurs de la production, de la cheffe d’atelier, du personnel au SAV. Après avoir consigné toutes leurs demandes 
                                    et les avoir validées avec eux, j’ai continué en faisant des maquettes de l’application, des schémas fonctionnels de 
                                    l’application actuelle et futur ainsi qu’un schéma des processus de fonctionnement de la production et du SAV. De plus, 
                                    j’ai rédigé un cahier de recette pour valider la nouvelle version de l’application.
                                    <br />
                                    <br />
                                </Typography>
                                <Grid alignItems="center"  justify="center" container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/maquetteCreerProduit.png"} alt="Maquette nouvelle version - Creéer un produit" width="100%" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/maquetteCreerModele.png"}  alt="Maquette nouvelle version - Créer un template" width="100%" />
                                    </Grid>
                                </Grid>
                                <div align="center">
                                    <Typography variant="subtitle1">Maquettes de la nouvelle version - Créer un produit et un template</Typography>
                                </div>
                                <Typography align="justify">
                                    <br />
                                    Avec mes collègues impliqués dans cette refonte des logiciels internes, nous avons présenté notre avant-projet aux 
                                    différents services impactés, cela comprenait les personnes au bureau d’étude, à la production, au SAV et les opérations.
                                    <br />
                                    Cette présentation présentait les différents outils utilisés dans le processus de production, détaillait les limites 
                                    des outils, les objectifs des nouvelles versions des différentes applications ainsi que notre méthode de travail.
                                    <br />
                                    <br />
                                </Typography>
                                <Grid alignItems="center"  justify="center" container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/avIntro.png"} alt="Support de présentation - Les différentes applications" width="100%" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/avObjectifs.png"}  alt="Support de présentation - Nos intentions" width="100%" />
                                    </Grid>
                                </Grid>
                                <div align="center">
                                    <Typography variant="subtitle1">Support de présentation de l'avant-projet</Typography>
                                </div>
                                <Typography align="justify">
                                    <br />
                                    Une fois notre feuille de route communiquait à toutes les personnes impliquées par les changements, j’ai commencé à 
                                    travailler sur le projet. La réalisation de la nouvelle version de l’application de suivi de production a été divisée 
                                    en deux parties. La première était l’amélioration de la version actuelle avec des nouvelles 
                                    fonctionnalités et des modifications ne pouvaient attendre la nouvelle version. Si un besoin d’un utilisateur entraîné 
                                    une modification d’une fonctionnalité déjà existant alors celle-ci était alors faite en PHP. Comme par exemple, la vérification 
                                    des composants ajoutés par l’opérateur lors de la fabrication d’un lampadaire. Au contraire, si c’était une nouvelle 
                                    fonctionnalité alors elle devait être faite en React. Pour limiter les erreurs durant un assemblage, j’ai développé une 
                                    fonctionnalité de « Création de template ». Cette fonctionnalité permet de décrire les différents composants qui 
                                    composent un lampadaire.
                                    <br />
                                    La deuxième partie était le développement de l’application de suivi de production complètement en React. Malheureusement, 
                                    jusqu’à maintenant je n’ai eu que peu de temps pour travailler sur cette partie.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Résultat
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    À ce jour, la nouvelle version de l’application n’est toujours pas finie. Cependant, les nouvelles fonctionnalités qui 
                                    sont incorporées à l’ancienne version devront prochainement être utilisables. Celles-ci permettront de limiter les erreurs 
                                    des utilisateurs lorsqu’ils fabriquent des produits, donc de fiabiliser les processus de production. Ce projet m’a permis 
                                    d’apprendre un nouveau langage ainsi que de prouver que j’étais flexible et organisé.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Ce projet m’a permis de découvrir et d’utiliser React ainsi que remettre en question tout mon travail réalisé lors de la 
                                    première version de l’application.
                                    <br />
                                    À ce jour, ce projet n’est qu’au commencement et devrait se poursuivre sur les mois à venir.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/react" clickable color="primary" label="React" variant="outlined" />
                                <Chip component={Link} to="/organisation" clickable color="primary" label="Organisation" variant="outlined" />
                                <Chip component={Link} to="/communication" clickable color="primary" label="Communication" variant="outlined" />
                                <Chip component={Link} to="/appliDeSuivi" clickable color="primary" label="Application suivi de production" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}