import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Button, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function Php() {
    const classes = useStyles();
    useEffect(() => {
        document.title = "Php";
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Php
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/ima_php.jpg"}
                                title="PHP"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    PHP (Hypertext Preprocessor) est un 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Langage_de_programmation" target="bang">
                                        langage de programmation
                                    </a> 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Logiciel_libre" target="bang">
                                        libre
                                    </a> 
                                    , 
                                    spécialement conçu pour le développement d'
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Page_web_dynamique" target="bang">
                                        applications web dynamiques
                                    </a> 
                                    . Ce langage a été utilisé pour la réalisation de sites tels que Facebook ou encore Wikipédia.
                                    <br />
                                    <br />
                                    En 2020, environ 80 % des sites internet sont faits en PHP. Ce chiffre permet donc de comprendre l’importance de 
                                    cette compétence puisqu’il y a une forte probabilité qu’elle soit sollicitée lors d’un développement web.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    J’ai plusieurs fois eu recours au langage PHP que ce soit pour la réalisation d’un projet à l’école INTECH ou dans 
                                    mon parcours professionnel.
                                    <br />
                                    <br />
                                    Lors de mon semestre 4 à INTECH, mon groupe et moi-même avons eu comme projet la création d’une application mobile et web. 
                                    <br />
                                    Le projet était de réaliser une application mobile qui facilite le choix d’un vin à partir d’un plat végétarien et inversement. 
                                    En plus d’avoir réalisé l’application mobile, mon groupe devait créer un site internet pour administrer l’application mobile.
                                    <br />
                                    Le site devait permettre à l’administrateur d’éditer, de valider ou de supprimer les propositions de recettes, cépages, vins 
                                    et aliments faites via l’application mobile. De plus, le site devait permettre d’associer un vin à un aliment et / ou un plat 
                                    et inversement un plat ou un aliment à un cépage et / ou un vin.
                                    <br />
                                    <br />
                                    J’ai réalisé le site web avec le langage PHP, cela m’a permis de communiquer avec la base de données, de stocker et de 
                                    modifier les informations qui allaient être disponibles sur l’application mobile. Ayant réalisé le site internet en peu de 
                                    temps et toute seule, j’ai pu fortement améliorer ma compétence.
                                    <br />
                                    Ce projet a été donné au commanditaire malgré le fait que l’application mobile ne comprenne pas toutes les fonctionnalités 
                                    inscrites dans le cahier des charges. Cependant, le commanditaire a été très satisfait du travail accompli par l’équipe.
                                    <br />
                                    <br />
                                    Lors de mon stage et de mon alternance, j’ai développé et amélioré l’
                                        <Link className={classes.link} to="/appliDeSuivi">application de suivi de production</Link>
                                    . 
                                    Cette application permet de stocker toutes les informations sortantes de la production et permet donc de faire 
                                    de la traçabilité sur les produits ainsi que sur les composants qui les contiennent. 
                                    <br />
                                    Cela fait 2 ans que la fabrication des lampadaires est faite avec mon application et représente plus de 80 % des 
                                    données enregistrées via celle-ci.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    Je pense avoir une très bonne maîtrise de PHP, grâce aux différentes expériences que j’ai eues avec ce langage. 
                                    Néanmoins, dans mon utilisation quotidienne, je n’utilise pas toutes les fonctionnalités que propose PHP. Il 
                                    faudrait que j’approfondisse mes connaissances sur ces parties-là pour avoir pleinement une maîtrise de ce langage.
                                    <br />
                                    PHP est un langage dont l’apprentissage est simple et rapide. Il est surtout incontournable si on veut faire du 
                                    développement web. De plus, il est très populaire et possède une forte communauté même s’il a des défauts. C’est pour 
                                    cela que je conseillerais de définir des conventions dès le départ pour garder une certaine logique dans le travail réalisé 
                                    et d’éviter de développer qu’en PHP natif. 
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    La majorité des sites internet sont faits en PHP donc cette compétence est importante surtout si je m’oriente vers du 
                                    développement web. 
                                    <br />
                                    Cependant, je me rends bien compte que je vais devoir utiliser des 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Framework" target="bang">
                                        frameworks
                                    </a> 
                                    {" "} 
                                    tels que 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Symfony" target="bang">
                                        Symfony
                                    </a> 
                                    {" "}
                                    ou 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Laravel" target="bang">
                                        Laravel
                                    </a> 
                                    {" "}
                                    dont je n'ai aucune connaissance. Pour cela et pour diversifier mes compétences, je vais commencer à m'autoformer sur 
                                    l'un de ces frameworks.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/appliDeSuivi" clickable color="primary" label="Application suivi de production" variant="outlined" />
                                <Chip component={Link} to="/refonteAppliDeSuivi" clickable color="primary" label="Refonte application suivi de production" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}