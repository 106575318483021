import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Button, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    link: {
        textDecoration: 'none',
        color: "inherit",
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function Pedagogie() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Pédagogie';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Pédagogie
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/peda.jpg"}
                                title="Pédagogie"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    La pédagogie désigne l'art d'enseigner, de former et de transmettre à une ou plusieurs personnes. 
                                    Ce terme rassemble les méthodes et pratiques d'enseignement requises pour transmettre un savoir, 
                                    un savoir-faire et un savoir-être.
                                    <br />
                                    <br />
                                    Dans un contexte professionnel, cette compétence va être utilisée par exemple lorsqu’un nouvel outil 
                                    est mis en place dans l’entreprise, il faut alors apprendre aux utilisateurs à s’en servir. De même, 
                                    il faut faire preuve de pédagogie si l’on veut transmettre à un collègue ses connaissances sur une 
                                    technologie ou un projet.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Dans mon parcours scolaire et professionnel, j'ai eu l'occasion de mettre en pratique plusieurs fois cette compétence.
                                    <br />
                                    <br />
                                    Tout d'abord, lors de ma deuxième année de formation à INTECH, j'ai eu l'opportunité d'apprendre la langue 
                                    des signes grâce à un projet proposé par l'association SEM24-47. 
                                    Je me suis inscrite à la session de septembre, pour assister à une dizaine de séances.
                                    <br />
                                    À chaque séance, un thème était abordé, comme par exemple :
                                    <br />
                                        - Comment signer, poser des questions et répondre négativement
                                    <br />
                                        - La famille
                                    <br />
                                        - L'alphabet
                                    <br />
                                        - Les jours de la semaine, les mois, les saisons, ...
                                    <br />
                                        - Les couleurs
                                    <br />
                                        - Les métiers
                                    <br />
                                        - Les nombres et les chiffres
                                    <br />
                                        - Les animaux
                                    <br />
                                    À la suite de ces séances, nous avons décidé avec mon groupe d'initier les étudiants et au personnel encadrant qui le souhaitaient, 
                                    la langue des signes. Pour cela, on a choisi un thème qui ne soit pas trop complexe et qui donne envie d'en découvrir plus 
                                    sur la langue des signes. Nous avons décidé de leur apprendre l'alphabet puis d'enchaîner avec un jeu.Le jeu étant qu’un 
                                    participant devait choisir un mot et signer les lettres de celui-ci et les autres participants devaient trouver le mot. 
                                    Cet atelier m’a permis de faire découvrir à mes camarades la langue des signes qu’ainsi de partager avec eux une cause 
                                    qui me tient à cœur. À la suite de cet atelier découverte, nous avons envoyé un sondage aux participants pour savoir si 
                                    l’atelier leur avait plu et si cela leur avait donné envie de continuer l’apprentissage de la langue des signes.
                                    {/* Après avoir assisté à plus d'une diziane de scèances, où j'ai appris comment signer, 
                                    l'alphabet, comment poser des questions, les mois et les saisons, les animaux, la famille, ... . 
                                    Avec le groupe "Parl&signe", nous avons décidé d'initier la langue des signes aux étudients et 
                                    aux enseignents qui le souhaité.*/}
                                    <br />
                                    <br />
                                </Typography>
                                
                                <Grid alignItems="center" className={classes.gridImg} justify="center" container spacing={2}>
                                    <Grid align="center" item xs={12} sm={10}>
                                        <img src={process.env.PUBLIC_URL + "/initiationLSF.png"} alt="Montage photos - Etudiants et formateurs apprenant l'alphabet en langue des signes" width="100%" />
                                    </Grid>
                                </Grid>
                                <div className={classes.caption} align="center">
                                    <Typography variant="subtitle1">Photos de l'initiation à la langue des signes</Typography>
                                </div>
                                    
                                <Typography align="justify">
                                    <br />
                                    <br />
                                    Lors de mon alternance, Fonroche Lighting a racheté l'entreprise américaine SolarOne. Pour que cette 
                                    filiale puisse exporter les lampadaires aux États-Unis, j'ai dû leur présenter les outils 
                                    nécessaires à la production de lampadaires. Pour cela, j'ai dû 
                                    {" "}
                                        <Link className={classes.link} to="/actionDeFormation">former en anglais mes collègues américains</Link>
                                    {" "}
                                    sur les outils de production dont l'
                                        <Link className={classes.link} to="/appliDeSuivi">application de suivi de production</Link>
                                    . Cette formation était indispensable pour pouvoir utiliser au mieux les outils et pour la fabrication 
                                    des lampadaires. À ce jour, ils ont fabriqué un peu plus de 600 lampadaires. De plus, cette formation 
                                    m’a permis de m’améliorer en anglais, que ce soit pour l’expression ou la compréhension orale.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    Étant une personne assez réservée, c'est toujours un peu stressant de faire des formations devant plusieurs 
                                    personnes. Cependant, je pense être capable de mettre en place des actions de formation, sur un logiciel, 
                                    un projet ou une technologie. Être pédagogue est important, car lorsque l’on met au point un logiciel, on est 
                                    en charge de former les utilisateurs, à travers des documentations et des formations.
                                    <br />
                                    D’un point de vue personnel, le fait de transmettre une connaissance ou un savoir-faire est une satisfaction 
                                    et donne un sentiment d’utilité. 
                                    À ce jour, j'ai plusieurs fois fait usage de cette compétence. Néanmoins, celle-ci se peaufine avec l'expérience 
                                    et donc je ne peux que m'améliorer. Si je devais donner un conseil alors ce serait de toujours se remettre en 
                                    question à la suite d’une action de formation pour s’améliorer et gagner en compétence.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Essentielle et présente dans la vie de tous les jours, la pédagogie est une compétence importante dans le 
                                    monde du travail, mais aussi dans sa vie personnelle.
                                    <br />
                                    Il peut arriver qu'une personne puisse avoir besoin d'aide et il faut donc la conseiller au mieux en partageant nos 
                                    connaissances et notre expérience. De plus, elle est nécessaire pour dans un contexte professionnel, pour la 
                                    transmission des compétences, la pleine maîtrise d'un projet ou encore la réussite du projet. C’est pour cela, 
                                    que je souhaiterais m’améliorer, mais il ne faut pas oublier que cette compétence est étroitement liée à la 
                                    compétence de communication. Donc, si je veux m’améliorer, il faut que je travaille sur ces deux axes.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/actionDeFormation" clickable color="primary" label="Action de formation" variant="outlined" />
                                <Chip component={Link} to="/appliDeSuivi" clickable color="primary" label="Application de suivi de production" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}