import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Button, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function Android() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Java';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Java
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/android3.jpg"}
                                title="Java"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    Java est un langage de {" "}
                                        <a className={classes.link} href="https://fr.wikipedia.org/wiki/Programmation_orient%C3%A9e_objet" target="bang">
                                            programmation orienté objet
                                        </a>. 
                                    Il permet de créer des logiciels compatibles avec de nombreux 
                                    systèmes d’exploitation. Java donne aussi la possibilité de développer des applications pour téléphones portables, 
                                    assistants personnels ou encore pour le web.
                                    <br />
                                    <br />
                                    Il y a quelques mois, c’est conclu le procès en Google et {" "}
                                        <a className={classes.link} href="https://fr.wikipedia.org/wiki/Oracle_(entreprise)" target="bang">
                                            Oracle
                                        </a>. 
                                    La Cour suprême américaine a jugé que Google n'avait 
                                    pas enfreint le droit d'auteur d'Oracle sur Java qui a été utilisé pour créer le système d’exploitation mobile Android.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Lors de mon cursus au sein d’INTECH, durant mon semestre 4, mon groupe et moi-même avions comme projet la création d’une 
                                    application mobile et web.
                                    <br /> 
                                    Le projet était de réaliser une application mobile qui facilite le choix d’un vin à partir d’un plat végétarien et inversement. 
                                    En plus d’avoir réalisé l’application mobile, mon groupe devait créer un site internet en PHP pour administrer l’application mobile.
                                    <br />
                                    L’application devait permettre à un utilisateur de rechercher un vin, une recette ou un aliment. Ainsi que d’effectuer une 
                                    recherche avancée, comme par exemple : l’utilisateur cherche un plat qui irait bien avec un vin qu’il possède. L’application 
                                    permet aussi à l’utilisateur d’étoffer la base de données en ajoutant un nouveau vin, plat ou aliment sur la plateforme. 
                                    Cette proposition sera alors visible dans la liste des propositions faites par l’utilisateur et en attente de validation 
                                    par l’administrateur. L’utilisateur pouvait aussi ajouter dans ses favoris une carte, que ce soit vin, aliment ou plat dans 
                                    cette rubrique.
                                    <br />
                                    Ce projet a été donné au commanditaire malgré le fait que l’application mobile ne comprenne pas toutes les fonctionnalités 
                                    inscrites dans le cahier des charges. Cependant, le commanditaire a été très satisfait du travail accompli par l’équipe.
                                    <br />
                                    <br />
                                    Lors de mon alternance à Fonroche Lighting, j’ai eu l’occasion de réaliser une 
                                    {" "}
                                        <Link className={classes.link} to="/appliMobile">application mobile</Link>
                                    {" "} 
                                    permettant de piloter un parc de lampadaires. 
                                    <br />
                                    Ce projet a été une opportunité pour moi de renforcer mes acquis, ainsi que d’apprendre de nouvelles choses que cela soit 
                                    sur le langage, les bonnes pratiques ou encore sur les 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Biblioth%C3%A8que_logicielle" target="bang">
                                        librairies
                                    </a> 
                                    {" "} 
                                    liées au développement mobile. Malheureusement, le projet n’a pas pu être finalisé. Cependant, j’ai livré l’application avec 
                                    toutes les fonctionnalités inscrites dans le cahier des charges.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    Mes seules expériences avec Java se cantonnant au développement d’application mobile, dont je pense avoir une assez bonne maîtrise. 
                                    Cependant, ce n’est pas la seule utilisation qu’on peut faire avec Java et dont j’ai peu eu l’occasion de pratiquer.
                                    Il me reste encore une large marge de progression, malgré mon bon niveau de maîtrise de la compétence.
                                    <br />
                                    Si je souhaite continuer dans le développement d’application mobile, je vais devoir diversifier mes compétences car les 
                                    préconisations ne recommandent plus Java pour le développement des applications Android. C’est pour cela que je conseillerai 
                                    de ne pas choisir ce langage si le but est la création d’une application pour smartphone.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Je me suis rendu compte en fur et à mesure des réalisations des différentes applications mobiles, que j’appréciais de faire 
                                    du développement mobile. Je souhaiterais continuer à travailler avec ce langage pour perfectionner mes connaissances et 
                                    découvrir tout l’univers qui gravite autour de Java.
                                    <br />
                                    Cependant, Java n’est pas le meilleur langage pour développer des applications mobiles. Par exemple, 
                                    {" "} 
                                    <a className={classes.link} href="https://fr.wikipedia.org/wiki/Kotlin_(langage)" target="bang">
                                        Kotlin
                                    </a>
                                    {" "} 
                                    est un langage plus récent et qui à terme devrait remplacer Java pour les applications Android. C’est pourquoi je 
                                    souhaiterais me former sur ce langage.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/appliMobile" clickable color="primary" label="Application mobile" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}