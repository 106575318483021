import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Divider, Grid, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    gridImg: {
        padding: theme.spacing(2, 0, 0, 0),
    },
    caption: {
        padding: theme.spacing(0, 0, 2, 0),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function AppliDeSuivi() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Application mobile';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Application mobile de pilotage de lampadaire
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/appliMobile.jpg"}
                                title="Application mobile de pilotage de lampadaire"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    Projet réalisé lors de ma première année d'alternance chez Fonroche Lighting. 
                                    Ma mission a été de développer une application mobile pour permettre aux clients de piloter leurs
                                    lampadaires.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    {/* Contexte */}
                                    L'entreprise Fonroche Lighting possédait un produit physique qui permettait à un client de contrôler son 
                                    parc de lampadaires à distance. Il est alors venu l’idée de dématérialiser le produit et de proposer une 
                                    application mobile qui permettrait d’allumer et d’éteindre un lampadaire. Cela offrait la possibilité de 
                                    pouvoir se déplacer dans l’espace.
                                    <br />
                                    De plus, une application mobile est plus facile à maintenir qu’un boîtier, par exemple pour un 
                                    découpage des zones. Sur le boîtier physique, il va être plus compliqué de rajouter un bouton 
                                    alors que via une application mobile le découpage des zones n’est que logique.
                                    <br />
                                    Un nouveau modèle de vente aurait été possible, vente de l’application oneshot, achat renouvelable 
                                    tous les ans, vente de la tablette.
                                    <br />
                                    <br />
                                    {/* Objectifs */}
                                    Mes objectifs étaient de faire une application mobile qui soit simple à utiliser où le client peut 
                                    paramétrer la configuration de ses lampadaires et les piloter. C'est-à-dire, séparer son parc en une ou 
                                    plusieurs zones et éteindre ou allumer un lampadaire ou une zone.
                                    <br />
                                    <br />
                                    {/* Enjeux */}
                                    Ce nouveau produit avait pour but de diversifier la gamme des produits proposés par l’entreprise et avait 
                                    pour cible les clients qui souhaitaient être indépendants dans le pilotage de leurs parcs de lampadaires. 
                                    <br />
                                    <br />
                                    {/* Risques */}
                                    La cible de ce projet étant le client, les risques étaient que l'application ne corresponde à aucun besoin, 
                                    que celle-ci ne fonctionne pas ou partiellement ce qui aurait mécontenté le client pour un produit qu'il lui 
                                    a été vendu. 
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Déroulé
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    Dans un premier temps, on m'a donné le cahier des charges ainsi qu'un schéma fonctionnel et des maquettes de la future application.
                                    <br />
                                    L'application mobile devait :
                                    <br />
                                    - être pour les appareils dont la version d’Android est supérieure à 8 (y compris)
                                    <br />
                                    - récupération des informations du client sur son parc de lampadaires
                                    <br />
                                    - permettre de créer des zones de lampadaire
                                    <br />
                                    - permettre d’allumer et d’éteindre un lampadaire
                                    <br />
                                    - permettre d’allumer et d’éteindre une zone de lampadaire
                                    <br />
                                    <br />
                                    <strong>Maquettes :</strong>
                                    <Grid alignItems="center" className={classes.gridImg} justify="center" container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <img src={process.env.PUBLIC_URL + "/StartConfiguration.png"} alt="Application mobile - Configuration du parc de lampadaires" width="100%" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <img src={process.env.PUBLIC_URL + "/ZoneConfiguration.png"} alt="Application mobile - Configuration du parc de lampadaires" width="100%" />
                                        </Grid>
                                        <Grid align="center" item xs={12} sm={6}>
                                            <img src={process.env.PUBLIC_URL + "/monitoring.png"} alt="Application mobile - Pilotage du parc de lampadaires" width="100%" />
                                        </Grid>
                                    </Grid>
                                    <div className={classes.caption} align="center">
                                        <Typography variant="subtitle1">Maquettes de l'application mobile de contrôle des lampadaires</Typography>
                                    </div>
                                    Dans un premier temps, je me suis reformée sur 
                                        {" "}
                                            <Link className={classes.link} to="/java">Java</Link>
                                        {" "}
                                    pour Android via les cours fournis par l’école. Une fois avoir assimilé les bases, 
                                    j’ai commencé à réaliser le projet sur Android Studio et à utiliser 
                                        {" "}
                                            <Link className={classes.link} to="/git">Git</Link>
                                        {" "}
                                    qui est directement intégré dans l’outil. Ma partie se cantonnait à la création de l’application et non pas à la communication 
                                    avec les lampadaires.
                                    <br />
                                    Étant en totale 
                                        {" "}
                                            <Link className={classes.link} to="/autonomie">autonomie</Link>
                                        {" "}
                                    pour le développement de l’application, j’ai d’abord privilégié la structure de la base de données et 
                                    le corps de l’application. C’est-à-dire la barre de navigation, que l’application soit bloquée en mode paysage et en plein écran 
                                    ainsi que toutes les vues.

                                    <br />
                                    <br />
                                    Au début du projet, l’application était constituée de trois vues :
                                    <br />
                                    - La vue d’accueil constituée d’un carrousel (système de défilement d’images) avec des photos des projets réalisés par Fonroche Lighting
                                    <br />
                                    - La vue de profil, celle-ci permettait à l’utilisateur de renseigner son nom et prénom pour personnaliser l’application
                                    <br />
                                    - La vue de contrôle des lampadaires constituée une carte sur la gauche où est indiqué l’emplacement des lampadaires. 
                                    Sur la droite, la liste des lampadaires regroupés par les zones où l’utilisateur peut choisir d’allumer ou d’éteindre la zone ou un lampadaire
                                    <br />
                                    <br />
                                    La partie la plus complexe du projet était la synchronisation des états entre les lampadaires et la zone qui leur était rattachée. 
                                    Par exemple, si l’utilisateur appuyait sur le bouton de la zone pour éteindre les lampadaires alors tous les statuts des lampadaires 
                                    devaient changer. Inversement, si l’utilisateur voulait éteindre un lampadaire. {/* Une question devait alors se poser : quel était le 
                                    statut de la zone et quel est le comportement de l’application si l’utilisateur appuie sur le bouton éteindre ou allumer si tous les 
                                    lampadaires n’ont pas le même état. */}
                                    <br />
                                    Mon collègue a ajouté une partie qui simulait la commande d’extinction ou d’allumage d’un lampadaire. Si la commande est un succès, 
                                    l’utilisateur en est informé. De même, si la commande est un échec, le statut ne change pas et l’utilisateur est informé de l’échec. 
                                    J’ai donc modifié l’application pour intégrer cette partie dans la gestion des états de la zone et du lampadaire.
                                    <br />
                                    <br />
                                    Après avoir créé les vues précédemment citées, j’ai ajouté la vue d’activation du produit. Celle-ci permet au client de récupérer les 
                                    informations de son parc de lampadaires via le code d’activation.
                                    <br />
                                    Pour que l’utilisateur puisse configurer son parc de lampadaires, j’ai créé une vue où l’utilisateur peut renseigner un nom pour une 
                                    zone, de créer ou de supprimer des zones. De plus, il peut choisir d’affecter un lampadaire à la zone qu’il souhaite.
                                    <br />
                                    <Grid alignItems="center" className={classes.gridImg} justify="center" container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <img src={process.env.PUBLIC_URL + "/configuration.jpg"} alt="Application mobile - Pilotage du parc de lampadaires" width="100%" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <img src={process.env.PUBLIC_URL + "/controleLampadaires.jpg"} alt="Application mobile - Configuration du parc de lampadaires" width="100%" />
                                        </Grid>
                                    </Grid>
                                    <div className={classes.caption} align="center">
                                        <Typography variant="subtitle1">Photos de l'application mobile de contrôle des lampadaires</Typography>
                                    </div>
                                    Une nouvelle idée a alors été émise : proposer deux offres du produit au client. La première, le client aurait la possibilité de configurer 
                                    en plusieurs zones son parc de lampadaires. La deuxième étant que le client ne peut pas faire de configuration et serait donc limité 
                                    à une seule zone.
                                    <br />
                                    <br />
                                    Après avoir géré les modifications des états des lampadaires et des zones, la configuration de celles-ci, j’ai fait un sort que la 
                                    carte de l’application soit disponible sans connexion internet. À la suite de cela, j’ai commencé à travailler sur la récupération 
                                    des données via une API.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Résultat
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    Malheureusement, ce projet n'a pas pu être concrétisé et est donc pour le moment abandonné. Le produit a été présenté 
                                    aux commerciaux. Cependant, les clients avaient une préférence pour le produit physique plutôt que pour l’application mobile.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    Ce projet m'a permis de refaire du développement mobile ainsi que de renforcer mes connaissances en Java et dans le 
                                    développement d’application mobile. Cependant, je suis attristé que le projet n’ait pas abouti. Malgré tout, je suis 
                                    persuadée que l’idée de ce nouveau produit est bonne et que dans l’avenir le projet pourrait être repris et terminé. 
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/java" clickable color="primary" label="Java" variant="outlined" />
                                <Chip component={Link} to="/autonomie" clickable color="primary" label="Autonomie" variant="outlined" />
                                <Chip component={Link} to="/git" clickable color="primary" label="Git" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}