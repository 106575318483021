import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Button, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function Git() {
    const classes = useStyles();
    useEffect(() => {
        document.title = "Git";
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Git
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/git.jpg"}
                                title="Git"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify" > 
                                    Git est un logiciel de gestion de versions de fichiers décentralisé. Git va faire un 
                                    historique des différents fichiers d’un projet et permet de stocker le projet ailleurs que sur la 
                                    machine de l’utilisateur. De plus, ce logiciel facilite le partage d’un projet entre les différents  
                                    contributeurs. Chaque utilisateur peut récupérer une version du projet sur sa machine et y travailler 
                                    dessus sans gêner les autres utilisateurs qui travaillent sur le même projet.
                                    <br />
                                    Un outil indispensable lorsque l’on travaille à plusieurs sur un projet et encore plus actuellement à travers 
                                    les différents confinements et phases de télétravail. C’est un outil couramment utilisé durant la phase 
                                    de développement de logiciels afin de conserver les différentes versions d’un projet.
                                    <br />
                                    En 2019, plus de 70 % des projets qui souhaitaient faire une gestion de versions utilisait Git.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify" >
                                    J’ai utilisé Git plusieurs fois dans mes projets réalisés à l’école, à l’entreprise Fonroche Lighting 
                                    ainsi que dans ma réalisation de ce portfolio. 
                                    <br />
                                    <br />
                                    Lors de ma deuxième année à INTECH, j’ai commencé à utiliser le logiciel Git pour mettre en commun le 
                                    travail de chacun et travailler à chaque fois sur la dernière version de notre projet informatique. 
                                    Depuis ce moment, j’ai utilisé systématiquement Git pour tous les projets effectués à l’école.
                                    <br />
                                    <br />
                                    Par exemple lors de mon semestre 4, mon groupe avait architecturé le git en sept branches : master, 
                                    recette, test, dev ainsi qu’une branche pour chaque membre du groupe. Nous avons choisi de mettre en 
                                    place cette configuration pour que le dépôt soit propre et intuitif. Chaque membre du groupe développait 
                                    une fonctionnalité sur sa branche. Une fois la fonctionnalité terminée, celle-ci était mise sur la branche 
                                    dev pour la mise en commun des différents développements puis mise en test. Pour chaque présentation de 
                                    l’application, nous avons mis la version de test sur la branche recette et tagué la version. À la 
                                    fin du projet, nous avons mis la version finale sur la brache master.
                                    <br />
                                    <br />
                                    Lors de mon alternance à Fonroche Lighting, j'ai utilisé Git via <a className={classes.link} href="https://fr.wikipedia.org/wiki/Android_Studio" target="bang" >Android Studio</a> pour le développement de 
                                    l’
                                    <Link className={classes.link} to="/appliMobile">application mobile</Link>
                                    . Le dépôt Git est composé de deux branches : master et dev. Ayant travaillé seule sur ce projet, je 
                                    n’ai pas créé de branche spécifiquement pour moi.
                                    <br />
                                    <br />
                                    Lors de la réalisation de ce portfolio, j’ai utilisé Git. Cela m’a permis d’avoir un historique de l’ensemble 
                                    des articles présents sur le site.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify" >
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    Malgré des débuts difficiles, dû à son utilisation en ligne de commande, je pense maîtriser le logiciel 
                                    même si je n’ai pas une utilisation très poussée des fonctionnalités. Celle-ci se limite aux commandes 
                                    les plus courantes telles que init, clone, status, add, pull, push, merge, … .
                                    <br />
                                    Cependant, j’ai plein de choses à apprendre sur ce logiciel et à mettre en pratique comme par exemple les tags.
                                    <br />
                                    La place de cette compétence dans mon métier est très importante, car elle peut influencer le déroulé d’un projet.
                                    <br />
                                    Je conseillais de mettre en place systématiquement Git dès le début du projet. Son utilisation peut aider 
                                    lors des mises en production par exemple. Néanmoins, si on n’utilise pas régulièrement le logiciel, 
                                    les commandes et les bonnes pratiques s’oublient vite.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify" > 
                                    Git est un logiciel incontournable lorsque l’on fait du développement d’application. Même si son 
                                    utilisation en ligne de commande peut être déroutante, c’est un outil puissant qui permet de versionner 
                                    et partager le projet avec ses collègues. C’est pour cela que je souhaiterais continuer à me former sur ce logiciel.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/appliMobile" clickable color="primary" label="Application mobile" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}