import React, { useEffect } from "react";
import TemplateCard from './TemplateCard';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    title: {
        //paddingTop: theme.spacing(8),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
}));

export default function Realisation() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Mes réalisations';
        window.scrollTo(0, 0);
    });
    const cards = [
                    {
                        id: 1,
                        image: process.env.PUBLIC_URL + "/appliDeSuivi2.jpg",
                        title: "Application de suivi de production",
                        stars: 0,
                        describe: "Projet réalisé lors de mon stage chez Fonroche Lighting. Ma mission a été de développer une application industrielle de suivi de production permettant d'effectuer de la traçabilité sur les produits et les composants.",
                        page: "/appliDeSuivi",
                        skill: [
                            {
                                nameSkill: "Php",
                                pageSkill: "/php" 
                            },
                            {
                                nameSkill: "SQL",
                                pageSkill: "/sql" 
                            },
                            {
                                nameSkill: "Communication",
                                pageSkill: "/communication" 
                            }
                        ]
                    }, 
                    {
                        id: 2, 
                        image: process.env.PUBLIC_URL + "/appliMobile.jpg",
                        title: "Application mobile de pilotage de lampadaire",
                        stars: 0,
                        describe: "Projet réalisé lors de ma première année d'alternance chez Fonroche Lighting. Ma mission a été de développer une application mobile pour permettre aux clients de piloter leurs lampadaires.",
                        page: "/appliMobile",
                        skill: [
                            {
                                nameSkill: "Java",
                                pageSkill: "/java" 
                            },
                            {
                                nameSkill: "Autonomie",
                                pageSkill: "/autonomie" 
                            },
                            {
                                nameSkill: "Git",
                                pageSkill: "/git" 
                            }
                        ]
                    }, 
                    {
                        id: 3, 
                        image: process.env.PUBLIC_URL + "/refonteAppliDeSuivi.jpg",
                        title: "Refonte de l'application de suivi de production",
                        stars: 0,
                        describe: "Projet réalisé lors de ma deuxième année d'alternance chez Fonroche Lighting. Ma mission est d'améliorer et d'optimiser l'application de suivi de production réaliser pendant mon stage.",
                        page: "/refonteAppliDeSuivi",
                        skill: [
                            {
                                nameSkill: "React",
                                pageSkill: "/react" 
                            },
                            {
                                nameSkill: "Organisation",
                                pageSkill: "/organisation" 
                            },
                            {
                                nameSkill: "Communication",
                                pageSkill: "/communication" 
                            },
                            {
                                nameSkill: "Application de suivi de production",
                                pageSkill: "/appliDeSuivi" 
                            }
                        ]
                    }, 
                    {
                        id: 4, 
                        image: process.env.PUBLIC_URL + "/pedagogie.jpg",
                        title: "Action de formation",
                        stars: 0,
                        describe: "Formation réalisé lors de ma deuxième année d'alternance chez Fonroche Lighting. Cette action avait pour but de former mes collègues aux Etats-Unis sur l'application de suivi de production.",
                        page: "/actionDeFormation",
                        skill: [
                            {
                                nameSkill: "Communication",
                                pageSkill: "/communication" 
                            },
                            {
                                nameSkill: "Pédagogie",
                                pageSkill: "/pedagogie" 
                            },
                            {
                                nameSkill: "Application de suivi de production",
                                pageSkill: "/appliDeSuivi" 
                            }
                        ]
                    }, 
                    {
                        id: 5, 
                        image: process.env.PUBLIC_URL + "/journeeMotard2.png",
                        title: "Participation à un évènement",
                        stars: 0,
                        describe: "Projet réalisé lors de ma première année à l’école INTECH. La mission était de participer à l’organisation de la « Journée des motards », qui est un moment de partage, de rencontre, d’approfondissement des techniques de conduite et de sensibilisation.",
                        page: "/participationEvenement",
                        skill: [
                            {
                                nameSkill: "Organisation",
                                pageSkill: "/organistaion" 
                            },
                            {
                                nameSkill: "Communication",
                                pageSkill: "/communication" 
                            },
                            {
                                nameSkill: "Travail d'équipe",
                                pageSkill: "/travailEquipe" 
                            }
                        ]
                    }
                ];
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="sm" className={classes.title}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Mes Réalisations
                </Typography>
            </Container>
            <Container className={classes.cardGrid} maxWidth="lg">
                <Grid container spacing={4}>
                    {cards.map((card) => (
                        <TemplateCard key={card.id} card={card}/>
                    ))}
                </Grid>
            </Container>
        </div>
    );
}