import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Divider, Grid, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function ActionDeFormation() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Action de formation';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Action de formation
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/pedagogie.jpg"}
                                title="Action de formation"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    J'ai réalisé cette action de formation lors de ma deuxième année d'alternance chez Fonroche Lighting. 
                                    Cette action avait pour but de former mes collègues aux États-Unis sur l'
                                        <Link className={classes.link} to="/appliDeSuivi">application de suivi de production</Link>
                                    .
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte
                                    </Box>
                                </Typography>
                                <Typography align="justify">{/* Context Objectifs Enjeux Risques */}
                                    En 2020, Fonroche Lighting a racheté l'entreprise américaine Solar One. Cette nouvelle filiale de 
                                    l'entreprise est composée de commerciaux, d'un bureau d'étude et d'un site de production de lampadaires.
                                    Pour que nos nouveaux collègues puissent livrer des lampadaires, ils ont dû apprendre à utiliser 
                                    les outils que nous utilisons pour la production de lampadaires.
                                    <br />
                                    J'ai donc formé les équipes américaines sur les deux outils utilisés pour la fabrication et la 
                                    programmation des lampadaires. En prenant bien en compte que s'ils ne sont pas opérationnels, ils ne 
                                    pourront pas livrer les lampadaires aux clients.
                                    Si au terme de cette formation, ils n'étaient pas prêts cela aurait entraîné des retards de livraison, 
                                    une potentielle perte de clients et de réputation, ou une casse du produit.
                                    {/* RISQUES: Qu'ils ne soient pas prêt pour fabriquer des lampadaires */}
                                    {/* , il a fallu former les 
                                    collègues aux logiciels utilisés en production qui permettent la création du lampadaire. */}
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Déroulé
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Il a été convenu que la formation sur les outils de production allait être faite en visio-conférence. 
                                    Les participants ont été le responsable informatique, un ingénieur SAV et les salariés américains qui 
                                    étaient au nombre de cinq. Suite à l’information que c’était à moi de diriger cette action de formation, 
                                    j’ai planifié un créneau convenant à tous les participants en prenant en compte le décalage horaire avec 
                                    les États-Unis. 
                                    <br />
                                    Après avoir convenu d’une date avec tous les participants, j’ai commencé à travailler 
                                    sur le support de présentation. Celui-ci se découpait en deux parties. La première partie concernait 
                                    l'application de suivi de production tandis que la deuxième partie concernait le logiciel de programmation 
                                    des lampadaires. L'intégralité du support de formation a été rédigée en anglais. 
                                    <br />
                                    Une fois le support fini et validé par un de mes collègues, j’ai créé les comptes des utilisateurs sur 
                                    l’application de suivi de production. Pour parfaire cette formation, je me suis entraînée, car celle-ci 
                                    devait se faire en anglais et que je ne suis pas très à l’aise avec cette langue.
                                    <br />
                                    <br />
                                    Le jour de la formation :
                                    <br />
                                    Comme dit précédemment, était présent lors de cette formation mes cinq collègues américains. Le 
                                    responsable informatique et l’ingénieur SAV ont assisté à la formation pour traités d’autres 
                                    sujets tels que du matériels informatiques, des questions sur le SAV, … .
                                    <br />
                                    Dans un premier temps, je me suis présentée à l'équipe américaine puisqu'à ce moment-là, 
                                    c'était notre première rencontre. Puis j'ai enchaîné 
                                    {" "}
                                            <Link className={classes.link} to="/pedagogie">en leur expliquant</Link>
                                    {" "} 
                                    comment accéder à l’application et comment se connecter.
                                    <br />
                                    <br />
                                </Typography>
                                <Grid alignItems="center"  justify="center" container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/DiapoFormation.png"} alt="Support de présentation - Présentation" width="100%" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/DiapoFormation2.png"}  alt="Support de présentation - Connexion à l'application" width="100%" />
                                    </Grid>
                                </Grid>
                                <div align="center">
                                    <Typography variant="subtitle1">Photos du support de formation</Typography>
                                </div>
                                <Typography align="justify">
                                    <br />
                                    <br />
                                    Je leur ai ensuite présenté les différentes fonctionnalités qu'ils allaient utiliser au quotidien.
                                    Dans ces fonctionnalités, on pouvait retrouver l'ajout des utilisateurs, des clients, des commandes, 
                                    faire des recherches sur des produits et des composants. Pour chaque fonctionnalité, je faisais une pause
                                    pour que les participants puissent poser des questions.
                                    <br />
                                    <br />
                                </Typography>
                                <Grid alignItems="center"  justify="center" container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/DiapoFormation3.png"} alt="Support de présentation - Transfert d'un produit vers une nouveau commande" width="100%" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/DiapoFormation4.png"}  alt="Support de présentation - Recherche ciblée" width="100%" />
                                    </Grid>
                                </Grid>
                                <div align="center">
                                    <Typography variant="subtitle1">Photos du support de formation - Fonctionnalité de transfert de produit et de recherche</Typography>
                                </div>
                                <Typography align="justify">
                                    <br />
                                    <br />
                                    Sur la deuxième partie du support, était expliquée l’utilisation de l’outil qui permet de programmer les 
                                    batteries des lampadaires. J’ai donc expliqué au mieux comment se dérouler cette étape.
                                    <br />
                                    <br />
                                <Grid alignItems="center" className={classes.gridImg} justify="center" container spacing={2}>
                                    <Grid align="center" item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/DiapoFormation5.png"} alt="Support de présentation - Programmation des batteries" width="100%" />
                                    </Grid>
                                </Grid>
                                <div className={classes.caption} align="center">
                                    <Typography variant="subtitle1">Photos du support de formation - Fonctionnalité de programmation des batteries</Typography>
                                </div>
                                    Suite à la formation, j'ai envoyé le support de présentation ainsi que les identifiants aux différents
                                    participants.
                                    
                                    {/* Organiser la formation
                                    Préparation support
                                    Animer la formation */}
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Résultat
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Depuis ce jour, mes collègues américains utilisent quotidiennement les applications sans que le moindre 
                                    incident ne nous soit remonté. Cette formation était indispensable pour pouvoir utiliser au mieux
                                    les outils développés en interne. Ils ont produit un peu plus de 600 lampadaires. Cette formation 
                                    m’a permis de m’améliorer en anglais, que ce soit pour l’expression ou la compréhension orale.
                                    {/* Je pense que cette formation a été un succès puisque . */}
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    J'ai vraiment apprécié de faire cette formation puisqu'elle m'a permis de 
                                    {" "}
                                            <Link className={classes.link} to="/communication">m'exprimer</Link>
                                    {" "}
                                    dans une autre langue, même si j'appréhendais du fait de mon niveau en anglais. Heureusement, j'ai 
                                    pu compter sur mes collègues présents dans la salle avec moi, pour m'aider lorsque j'avais des problèmes 
                                    de compréhension ou d'expression. Par ailleurs, mes collègues américains ont été très prévenants et d'une 
                                    extrême gentillesse par rapport à mon niveau d'anglais.
                                    Cette formation m'a aussi permis de me prouver que je pouvais m'exprimer et débattre sur un sujet qui me 
                                    tenait à cœur et en anglais.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/pedagogie" clickable color="primary" label="Pédagogie" variant="outlined" />
                                <Chip component={Link} to="/appliDeSuivi" clickable color="primary" label="Application de suivi de production" variant="outlined" />
                                <Chip component={Link} to="/communication" clickable color="primary" label="Communication" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}