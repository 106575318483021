import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Card, CardContent, CardMedia, Grid, Divider, CardActions, Button, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function EspritEquipe() {
    const classes = useStyles();
    useEffect(() => {
        document.title = "Travail d'équipe";
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Travail d'équipe
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/espritEquipe2.jpg"}
                                title="Travail d'équipe"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    Le travail en équipe est un concept dans lequel des personnes vont interagir et s’organiser entre elles. 
                                    Chaque membre de l'équipe doit œuvrer non pas individuellement, mais avec tous les autres pour atteindre un 
                                    objectif commun. Ils doivent pour cela partager des valeurs communes telles que l’écoute, l’adaptabilité, 
                                    la compréhension de l’autre, l’entraide pour favoriser l’entente de tous les membres de l’équipe et éviter 
                                    des problèmes de communication.
                                    <br />
                                    <br />
                                    Dans un cadre professionnel, cette compétence va être employée pour la réalisation d’un projet ou d’un 
                                    objectif d’entreprise.
                                    <br />
                                    <br />
                                    Depuis quelques années, le télétravail est de plus en plus démocratisé et peut entraîner des difficultés 
                                    à la bonne cohésion d’une équipe.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Dans le cadre de ma formation, tous mes projets d'étudiants ont été réalisés en équipe. Cela concerne près d'une 
                                    dizaine de réalisations avec des équipes composées de 3 à 6 personnes.
                                    <br />
                                    <br />
                                    Par exemple, lors de l’
                                        <Link className={classes.link} to="/participationEvenement">organisation de la « Journée des motards »</Link>
                                    , nous étions une équipe de trois étudiants. 
                                    Le jour de l’événement, nous avons rencontré pour la première fois les étudiants de CAGO (IUT Gestion Administrative 
                                    et Commerciale des Organisations). Tous ensemble, nous avons dû accueil les participants lors de leur arrivée. 
                                    L’après-midi, nous avons corrigé ensemble les questionnaires de plus de 80 participants pour l’attribution des lots. 
                                    <br />
                                    Un de nos objectifs était de corriger efficacement le questionnaire pour préparer la distribution des lots de façon 
                                    optimale et de libérer les participants le plus tôt possible à la fin de la journée. Cette activité a permis de 
                                    renforcer nos liens, de nous entraider et de s’encourager pour finir le plus rapidement possible.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    Avec le temps, je remarque que le travail en équipe est plus complexe qu’il n’y paraît. Dans le métier du développement 
                                    web, nous sommes fréquemment amenés à travailler en équipe afin de faire évoluer le projet. La cohésion d’équipe est 
                                    donc un axe important pour atteindre un objectif commun.
                                    <br />
                                    <br />
                                    Grâce aux différents projets réalisés durant ma formation et de mon expérience professionnelle, je pense être capable 
                                    de bien travail en équipe même si on s’améliore avec l’expérience. Si je devais donner un conseil, ce serait celui-ci : 
                                    faire attention à bien impliquer tous les membres de son équipe et de partager toutes les informations. Pour que le 
                                    travail d’équipe fonctionne de la meilleure façon possible, il faut être à l’écoute de chaque membre de l’équipe, 
                                    être organisé et surtout bien communiquer, pour éviter la création de conflits.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Je me rends compte de l'importance de cette compétence dans un contexte professionnel. Cependant, je n’ai eu que 
                                    rarement l’occasion de mettre en pratique cette compétence. De plus, j’espère continuer de travailler au sein d’une 
                                    équipe pour mes futurs projets, car cette capacité permet d’améliorer ses autres compétences telles que la communication, 
                                    l’écoute des autres, l’entraide et bien d’autres.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/participationEvenement" clickable color="primary" label="Participation à un évènement" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}