import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    image: {
        width: "100%",
    },
}));

export default function NoMatch() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Page introuvable';
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Erreur 404
                </Typography>
                <img className={classes.image} alt="Erreur 404" src={process.env.PUBLIC_URL + "/not_found.png"} />
            </Container>
        </div>
    );
}