import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Box, Card, CardContent, CardMedia, Grid, Button, CardActions, Divider, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function Autonomie() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Autonomie';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Autonomie
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/autonomie2.jpg"}
                                title="Autonomie"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    L’autonomie est la capacité de s’occuper de la résolution d’un problème ou d’une tâche en étant seul. Être autonome, 
                                    c’est aussi savoir solliciter de l’aide ou bien demander des conseils lorsque l’on rencontre des problèmes pour 
                                    l’aboutissement du projet ou de la tâche.
                                    <br />
                                    Dans un contexte professionnel, l’autonomie est donc l’aptitude d’une personne à prendre des décisions et de s’adapter 
                                    pour surmonter des difficultés.
                                    <br />
                                    Par exemple, la mise en place du télétravail a permis de disposer d'une plus grande autonomie et de faciliter 
                                    l'organisation des tâches quotidiennes et donc de renforcer la compétence d’autonomie.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte d'utilisation
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    Que cela soit pour des projets réalisés lors de mon cursus à INTECH ou dans un contexte professionnel, j’ai eu plusieurs 
                                    fois l’occasion de mettre en pratique mon autonomie.
                                    <br />
                                    <br />
                                    Lors de mon alternance à Fonroche Lighting, particulièrement pour l’
                                        <Link className={classes.link} to="/appliMobile">application mobile</Link>
                                    {" "}
                                    servant à piloter un parc de lampadaires, 
                                    j’ai dû travailler en totale autonomie. Après quelques indications sur le projet telles que le but, la cible, les objectifs 
                                    et le cahier des charges, j’ai pu commencer à travailler sur le projet. Au terme du projet, j’ai fourni une application 
                                    correspondant aux différents objectifs énoncés dans le cahier des charges.
                                    <br />
                                    <br />
                                    Lors de mon stage et encore aujourd’hui, j’ai travaillé en partie en autonomie sur le projet de 
                                    {" "}
                                        <Link className={classes.link} to="/appliDeSuivi">suivi de production</Link>
                                    . 
                                    J’ai pu bénéficier de conseils de plusieurs de mes collègues sur les processus de production. Lors du développement 
                                    de l’application, j’ai dû prendre des décisions en sachant que celles-ci auraient des impacts sur la suite du projet 
                                    et sur l’utilisation de l’application. Mon autonomie et mes autres compétences telles que mon 
                                    {" "}
                                        <Link className={classes.link} to="/organisation">organisation</Link>
                                    , ma maîtrise de 
                                    {" "}
                                        <Link className={classes.link} to="/php">PHP</Link>
                                    {" "}
                                    et 
                                    {" "}
                                        <Link className={classes.link} to="/sql">SQL</Link>
                                    {" "}
                                    m’ont permis d’offrir aux utilisateurs une application qui soit la moins contraignante à utiliser.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Mon avis
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    <Box fontStyle="italic">
                                        Niveau de la compétence : 
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarRoundedIcon color="primary" />
                                        <StarBorderRoundedIcon color="primary" />
                                    </Box>
                                    <br />
                                    Dès le début de mon cursus à INTECH, l’autonomie a été une compétence sollicitée dans la réalisation des divers projets. 
                                    Je pense être autonome même si je sais qu’avec le temps, cette compétence s’améliorera et je ne dois pas hésiter à demander de 
                                    l’aide lorsque je bloque sur un sujet. L’autonomie est une compétence majeure pour un expert en ingénierie logicielle, même il 
                                    y a de fortes chances qu’il travaille en équipe sur des projets. 
                                    <br />
                                    <br />
                                    Si je devais donner un conseil vis-à-vis de cette compétence, je dirais qu’il est important de demander de l’aide à ses 
                                    collègues et de mettre tout en œuvre pour trouver une solution à un problème.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    L’autonomie est une compétence importante pour dans un cadre professionnel et a une place encore plus importante 
                                    pour un développeur. Elle permet d’accumuler les outils et les techniques qui seront utiles pour développer les 
                                    connaissances d’une personne. Cette compétence n’est donc pas à négliger et je pense qu’elle doit être utilisée 
                                    dès que possible, car cela permettra d’améliorer son savoir-faire et son savoir-être.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/appliMobile" clickable color="primary" label="Application mobile" variant="outlined" />
                                <Chip component={Link} to="/appliDeSuivi" clickable color="primary" label="Application suivi de production" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}