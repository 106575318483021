import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import NoMatch from './NoMatch';
import Home from './Home';
import Realisation from './Realisation';
import Skill from './Skill';
import AppliDeSuivi from './AppliDeSuivi';
import AppliMobile from './AppliMobile';
import LegalNotice from './LegalNotice';
import Footer from './Footer';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import RalewayWoff2 from './font/raleway-v19-latin-regular.woff2';
import { createMuiTheme, Fab, Zoom } from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';
import RefonteAppliDeSuivi from "./RefonteAppliDeSuivi";
import ActionDeFormation from "./ActionDeFormation";
import ParticipationEvenement from "./ParticipationEvenement";
import Autonomie from "./Autonomie";
import SkillReact from "./SkillReact";
import Communication from "./Communication";
import Organisation from "./Organisation";
import Android from "./Android";
import Sql from "./Sql";
import EspritEquipe from "./EspritEquipe";
import Git from "./Git";
import Php from "./Php";
import Pedagogie from "./Pedagogie";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const raleway = {
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('Raleway'),
      local('Raleway-Regular'),
      url(${RalewayWoff2}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const theme = createMuiTheme({
    typography: {
        fontFamily: 'Raleway, Arial',
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [raleway],
            },
        },
    },
    palette: {
        primary: {
            main : "#148F77",
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    navBar: {
        backgroundColor: '#148F77',
        [theme.breakpoints.down('sm')] : {
            display: "none",
        }
    },
    navBarMobile: {
        backgroundColor: '#148F77',
        [theme.breakpoints.down('sm')] : {
            display: "visible",
        },
        [theme.breakpoints.up('md')] : {
            display: "none",
        }
    },
    myName: {
        flexGrow: 1,
    },
    logo: {            
        maxWidth: 200,
        //maxWidth: 50,
        marginRight: theme.spacing(4),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
        marginRight: theme.spacing(4),
    },
    link: {
        textDecoration: 'none',
        color: "inherit",
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    tooltip: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(2),
    },
    fab: {
        backgroundColor: '#148F77',
        color:"white"
    },
}));

export default function MyRoute(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <AppBar position="fixed">
                        <Toolbar className={classes.navBar}>
                            <img src={process.env.PUBLIC_URL + "/name.png"} alt="logo" className={classes.logo} />
                            {/* <img src={process.env.PUBLIC_URL + "/favicon.png"} alt="logo" className={classes.logo} /> */}
                            <Typography variant="h6" className={classes.title}>
                                <Link className={classes.link} to="/">Accueil</Link>
                            </Typography>
                            <Typography variant="h6" className={classes.title}>
                                <Link className={classes.link} to="/realisations">Réalisations</Link>
                            </Typography>
                            <Typography variant="h6" className={classes.title}>
                                <Link className={classes.link} to="/competences">Compétences</Link>
                            </Typography>
                        </Toolbar>
                        <Toolbar className={classes.navBarMobile}>
                            <div className={classes.myName}>
                                <img src={process.env.PUBLIC_URL + "/name.png"} alt="logo" className={classes.logo} />
                            </div>
                            <IconButton
                                aria-label="menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                className={classes.menuItem}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose} component={Link} to="/" >Accueil</MenuItem>
                                <MenuItem onClick={handleClose} component={Link} to="/realisations" >Réalisations</MenuItem>
                                <MenuItem onClick={handleClose} component={Link} to="/competences" >Compétences</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    <Toolbar id="back-to-top-anchor" />
                    <Switch>
                        <Route path="/realisations">
                            <Realisation />
                        </Route>
                        <Route path="/appliDeSuivi">
                            <AppliDeSuivi />
                        </Route>
                        <Route path="/appliMobile">
                            <AppliMobile />
                        </Route>
                        <Route path="/refonteAppliDeSuivi">
                            <RefonteAppliDeSuivi />
                        </Route>
                        <Route path="/actionDeFormation">
                            <ActionDeFormation />
                        </Route>
                        <Route path="/participationEvenement">
                            <ParticipationEvenement />
                        </Route>
                        <Route path="/competences">
                            <Skill />
                        </Route>
                        <Route path="/autonomie">
                            <Autonomie />
                        </Route>
                        <Route path="/react">
                            <SkillReact />
                        </Route>
                        <Route path="/communication">
                            <Communication />
                        </Route>
                        <Route path="/organisation">
                            <Organisation />
                        </Route>
                        <Route path="/java">
                            <Android />
                        </Route>
                        <Route path="/pedagogie">
                            <Pedagogie />
                        </Route>
                        <Route path="/sql">
                            <Sql />
                        </Route>
                        <Route path="/travailEquipe">
                            <EspritEquipe />
                        </Route>
                        <Route path="/git">
                            <Git />
                        </Route>
                        <Route path="/php">
                            <Php />
                        </Route>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/mentionsLegales">
                            <LegalNotice />
                        </Route>
                        <Route path="*">
                            <NoMatch />
                        </Route>
                    </Switch>

                    <ScrollTop {...props}>
                        <Fab color="secondary" size="small" aria-label="scroll back to top">
                            <KeyboardArrowUpIcon />
                        </Fab>
                    </ScrollTop>

                    <Footer/>
                </div>
            </ThemeProvider>
        </Router>
    );
}

function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });
  
    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };
  
    return (
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation" className={classes.tooltip}>
          {children}
        </div>
      </Zoom>
    );
  }
  
  ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };
/*function Skill() {
    const classes = useStyles();
    const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Mes Compétences
                </Typography>
            </Container>
            <Container className={classes.cardGrid} maxWidth="lg">
                <Grid container spacing={4}>
                    {cards.map((card) => (
                    <Grid item key={card} xs={12} sm={6} md={6}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image="https://source.unsplash.com/random"
                                title="Image title"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Heading
                                </Typography>
                                <Typography>
                                    This is a media card. You can use this section to describe the content.
                                </Typography>
                            </CardContent>
                            <CardContent className={classes.cardContent}>
                                <Divider variant="middle" />
                            </CardContent>
                            <CardContent className={classes.cardContent}>
                                <Box fontStyle="italic" fontWeight="fontWeightLight">
                                    Compétences :
                                </Box>  
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary">
                                    View
                                </Button>
                                <Button size="small" color="primary">
                                    Edit
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
}*/