import React from "react";
import {
  Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarHalfRoundedIcon from '@material-ui/icons/StarHalfRounded';
import { Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
    },
    cardContent: {
      flexGrow: 1,
    },
}));

export default function TemplateCard(props) {
    const classes = useStyles();
    let stars = [];
    for (let i = 0; i < 5; i++) {
        if (props.card.stars !== 0) {
            if (props.card.stars <= i) {
                stars.push(<StarBorderRoundedIcon key={i} color="primary" />);
            }
            else {
                stars.push(<StarRoundedIcon key={i} color="primary" />);
            }
        }
    }
//console.log(props);
    return (
        <Grid item key={props.card.id} xs={12} sm={6} md={6}>
            <Card className={classes.card}>
                
                    <CardActionArea component={Link} to={props.card.page}>
                        <CardMedia
                            className={classes.cardMedia}
                            image={props.card.image}//{process.env.PUBLIC_URL + props.card.image}
                            title={props.card.title}
                        />
                        {/*ENLEVER LA CLASSE POUR RETIRER LE PADDING AVANT DIVISER*/}
                        <CardContent className={classes.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                <Box fontStyle="italic" fontWeight="fontWeightBold">
                                    {props.card.title}
                                </Box>
                                {
                                    stars
                                }
                            </Typography>
                            <Typography>
                                {props.card.describe}
                            </Typography>
                            <Box display="flex" justifyContent="flex-end" >
                            {/* <Button component={Link} to={props.card.page} size="small" color="primary">
                                Suite
                            </Button> */}
                            </Box>
                        </CardContent>
                    </CardActionArea>
                
                
                <Divider variant="middle" />
                
                <CardContent className={classes.cardContent}>
                    <Box fontStyle="italic" fontWeight="fontWeightLight">
                        En lien avec :
                    </Box>
                </CardContent>
                <CardActions>
                    
                    {
                        props.card.skill.map((skill, i) => (
                            /* <Chip component={Link} to={skill.pageSkill} clickable color="primary" label={skill.nameSkill} variant="outlined" /> */
                            <Button key={i} component={Link} to={skill.pageSkill} size="small" color="primary">
                                {skill.nameSkill}
                            </Button>
                        ))
                    }
                    
                </CardActions>
            </Card>
        </Grid>
    );
}