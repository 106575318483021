import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Typography, Box, Card, CardContent, IconButton, CardMedia, Grid } from "@material-ui/core";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import SchoolIcon from '@material-ui/icons/School';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    rootMe: {
        padding: theme.spacing(2, 0, 2, 0),
    },
    paper: {
        padding: '6px 16px',
    },
    timelineTitle: {
        padding: theme.spacing(1, 0, 2),
    },
    timelineDate: {
        padding: theme.spacing(0, 0, 2),
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    secondaryTailDark: {
        backgroundColor: theme.palette.secondary.dark,
    },
    secondaryTailLight: {
        backgroundColor: theme.palette.secondary.light,
    },
    /* root: {
        display: 'flex',
      },
      details: {
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        flex: '1 0 auto',
      },
      cover: {
        width: "100%",
      },

      card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      cardMedia: {
        paddingTop: '56.25%', // 16:9
      },
      cardContent: {
        flexGrow: 1,
      },

    image: {
        width: "50%",
        borderRadius: "2%",
        margin: theme.spacing(1, 2, 1, 2),
        flexGrow: 1,
    },
    test: {
        flexGrow: 1,
    }*/

    root: {
        flexGrow: 1,
    },
    paperMe: {
        padding: theme.spacing(2),
        margin: 'auto',
    },
    image: {
        padding: theme.spacing(2, 0, 0, 0),
        display: 'flex',
        justifyContent: 'center',
    },
    img: {
        maxWidth: 250,
        maxHeight: 250,
        borderRadius: 10,
    },
    myPresentation: {
        padding: theme.spacing(4, 0, 2, 0),
    },
})); 

export default function Home() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Accueil';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    À propos de moi
                </Typography>
            </Container>
            <Container maxWidth="lg">
                <div className={classes.rootMe}>
                    {/* <Paper className={classes.paper} elevation={3}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <div className={classes.image}>
                                    <img className={classes.img} alt="complex" src={process.env.PUBLIC_URL + "/Image1.png"} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    
                                    <Grid item>
                                    <Typography gutterBottom variant="h5" component="h2">
                            {/* <Box fontStyle="italic" fontWeight="fontWeightBold">
                                COUCOU PETIT VISITEUR
                            </Box> */}
                        {/* </Typography>
                        <Typography>
                            Découvert un peu par hasard le monde d'informatique, 
                            Perceverente et perfectionniste
                            Mes passions : équitation et la lecture
                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper> */}
                    <Paper className={classes.paper} elevation={3}>
                        <div className={classes.image}>
                            <img className={classes.img} alt="Estelle Boudon" src={process.env.PUBLIC_URL + "/Image1.png"} />
                        </div>
                        {/* <Typography className={classes.myPresentation} align="justify" >
                            Pratiquant l’équitation depuis plus d’une dizaine d’années, cette passion m’a appris la patience. 
                            Elle m’a aussi enseigné qu’il fallait être à l’écoute aussi bien des autres que de soi-même.
                            <br />
                            <br />
                            Mon autre passion, la lecture. Pourtant, étant dyslexique, il y a encore quelques années, je détestais lire. 
                            Mais s’il y a bien quelque chose que cette différence m’a apporté ainsi que les difficultés que j’ai 
                            rencontrées, c’est la persévérance, l’organisation et qu’il ne fallait jamais baisser les bras. 
                            <br />
                            <br />
                            Pour moi, rien n'est plus gratifiant que de se sentir utile et de partager ces connaissances ainsi que 
                            de recevoir celles des autres.
                            <br />
                            Suite à l’obtention de mon baccalauréat, j’ai commencé ma formation d’expert en ingénierie du logiciel à INTECH. 
                            Aujourd’hui, étant arrivé à terme de ma formation et ayant accumulé deux années d’expérience en entreprise, je 
                            souhaiterais continuer dans le développement web.
                        </Typography> */}
                        <Grid alignItems="center" className={classes.gridImg} justify="center" container >
                            <Grid align="justify" className={classes.myPresentation} item xs={11} sm={9}>
                                {/* Pratiquant l’équitation depuis plus d’une dizaine d’années, cette passion m’a appris la patience. 
                                Elle m’a aussi enseigné qu’il fallait être à l’écoute aussi bien des autres que de soi-même.
                                <br />
                                <br />
                                Mon autre passion, la lecture. Pourtant, étant dyslexique, il y a encore quelques années, je détestais lire. 
                                Mais s’il y a bien quelque chose que cette différence m’a apporté ainsi que les difficultés que j’ai 
                                rencontrées, c’est la persévérance, l’organisation et qu’il ne fallait jamais baisser les bras. 
                                <br />
                                <br />
                                Pour moi, rien n'est plus gratifiant que de se sentir utile et de partager ces connaissances ainsi que 
                                de recevoir celles des autres.
                                <br />
                                Suite à l’obtention de mon baccalauréat, j’ai commencé ma formation d’expert en ingénierie du logiciel à INTECH. 
                                Aujourd’hui, étant arrivé à terme de ma formation et ayant accumulé deux années d’expérience en entreprise, je 
                                souhaiterais continuer dans le développement web. */}

                                De nature perfectionniste et persévérante, il est important pour moi que le travail soit bien fait et ce n’est pas 
                                des difficultés qui vont m’empêcher d’atteindre les objectifs que je me serais fixés. 
                                <br />
                                Depuis toujours, je mets un point d’honneur à aider les autres lorsqu’ils en ont besoin. Pour moi, l’entraide est 
                                un choix de vie qui mène à l’épanouissement et au partage. Pour moi, rien n'est plus gratifiant que de se sentir 
                                utile et de partager ces connaissances ainsi que de recevoir celles des autres.
                                <br />
                                Exigeante ? Je le suis avec moi-même, mais aussi avec mon entourage. Néanmoins, à travers mes différentes expériences, 
                                scolaires et professionnels, j’ai appris à doser mon exigence pour le bien des projets, de mon entourage et de moi.
                                <br />
                                <br />
                                Pratiquant l’équitation depuis plus d’une dizaine d’années, cette passion m’a appris la patience. Une qualité essentielle 
                                lorsque l’on travaille en équipe. Lorsque l’on monte à cheval, il y a une chose à ne pas oublier, c’est qu’il faut être 
                                à l’écoute aussi bien de l’animal que de soi. Ce conseil s’applique aussi dans un milieu professionnel, je sais être 
                                à l’écoute de l’équipe, des clients et des utilisateurs pour répondre au mieux à leurs besoins.
                                <br />
                                <br />
                                Mon autre passion, la lecture. Pourtant, étant dyslexique, il y a encore quelques années, je détestais lire. Mais s’il y 
                                a bien quelque chose que cette différence et les difficultés que j’ai rencontrées m’ont apporté, c’est la persévérance, 
                                l’organisation et qu’il ne fallait jamais baisser les bras.
                                <br />
                                <br />
                                Suite à l’obtention de mon baccalauréat, j’ai commencé ma formation d’expert en ingénierie du logiciel à INTECH. 
                                Aujourd’hui, étant arrivé à terme de ma formation et ayant accumulé deux années d’expérience en entreprise, je 
                                souhaite continuer dans le développement d’applications, de préférence web. 
                                <br />
                                Grâce à mon alternance, j’ai l’opportunité de faire du développement mobile. Ce projet m’a donné envie d’explorer 
                                cette voie pour la réalisation d’un futur projet personnel.
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
                    
                {/* <Paper elevation={3} className={classes.test}>
                <Grid container spacing={3}>
                <Grid item >
                    <img className={classes.image} src={process.env.PUBLIC_URL + "/Image1.png"} />
                    </Grid>
                    <Grid item xs={6}>
                    <Typography gutterBottom variant="h5" component="h2">
                            <Box fontStyle="italic" fontWeight="fontWeightBold">
                                COUCOU PETIT VISITEUR
                            </Box>
                        </Typography>
                        <Typography>
                            Depuis ma plus tendre enfance, je me passionne énormément pour la culture japonaise. Voulant en apprendre davantage, je commence à passer beaucoup de temps sur le web. Studieux, j’ai consacré du temps à mes études, passant par un BAC scientifique pour entrer ensuite en classe préparatoire aux grandes écoles mais ne sachant toujours pas ce que je voulais faire plus tard. Un jour, j’ai pris conscience que la réponse était juste sous mes yeux. Je voulais faire des sites web. J’ai donc quitté mon école pour rejoindre IN’TECH. Aujourd’hui, j’arrive à la fin de mon cursus avec un CDI à la main, c’est pourquoi je suis fier de vous présenter ce portfolio que j’ai moi-même développé.
                        </Typography>
                </Grid>
                </Grid>
                </Paper>  */}
                {/*<Card className={classes.root}>
                     <CardMedia
                        className={classes.cover}
                        image="https://source.unsplash.com/random"
                        title="Live from space album cover"
                    /> 
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography component="h5" variant="h5">
                                Live From Space
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                Bonjour, moi c'est Yoan Habib.
                                Depuis ma plus tendre enfance, je me passionne énormément pour la culture japonaise. Voulant en apprendre davantage, je commence à passer beaucoup de temps sur le web. Studieux, j’ai consacré du temps à mes études, passant par un BAC scientifique pour entrer ensuite en classe préparatoire aux grandes écoles mais ne sachant toujours pas ce que je voulais faire plus tard. Un jour, j’ai pris conscience que la réponse était juste sous mes yeux. Je voulais faire des sites web. J’ai donc quitté mon école pour rejoindre IN’TECH. Aujourd’hui, j’arrive à la fin de mon cursus avec un CDI à la main, c’est pourquoi je suis fier de vous présenter ce portfolio que j’ai moi-même développé.
                            </Typography>
                        </CardContent>
                    </div> 
                    <img width="25%" src="https://source.unsplash.com/random"/>
                </Card>*/}

                {/* <Card className={classes.card}>
                    <CardMedia
                        className={classes.cardMedia}
                        image= "https://source.unsplash.com/random" //{process.env.PUBLIC_URL + props.card.image}
                        title="Image title"
                    />
                    
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="h5" component="h2">
                            <Box fontStyle="italic" fontWeight="fontWeightBold">
                                COUCOU PETIT VISITEUR
                            </Box>
                        </Typography>
                        <Typography>
                            Depuis ma plus tendre enfance, je me passionne énormément pour la culture japonaise. Voulant en apprendre davantage, je commence à passer beaucoup de temps sur le web. Studieux, j’ai consacré du temps à mes études, passant par un BAC scientifique pour entrer ensuite en classe préparatoire aux grandes écoles mais ne sachant toujours pas ce que je voulais faire plus tard. Un jour, j’ai pris conscience que la réponse était juste sous mes yeux. Je voulais faire des sites web. J’ai donc quitté mon école pour rejoindre IN’TECH. Aujourd’hui, j’arrive à la fin de mon cursus avec un CDI à la main, c’est pourquoi je suis fier de vous présenter ce portfolio que j’ai moi-même développé.
                        </Typography>
                    </CardContent>
                </Card> */}

            </Container>
            <Container maxWidth="sm">
                <Typography  className={classes.myPresentation} component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
                    <Box fontStyle="italic">
                        Mon parcours
                    </Box>
                </Typography>
            </Container>
            <Container maxWidth="lg">
                <Timeline align="alternate">
                    <TimelineItem>
                        <TimelineOppositeContent />
                        <TimelineSeparator>
                            <TimelineDot className={classes.secondaryTailDark}>
                                <BusinessCenterIcon />
                            </TimelineDot>
                            <TimelineConnector className={classes.secondaryTailDark} />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                                <Grid container spacing={2} style={{paddingBottom:"5px"}}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography className={classes.timelineTitle} variant="h6" component="h1">
                                                    <Box fontWeight="fontWeightBold">
                                                        Alternance à Fonroche Lighting
                                                    </Box>
                                                </Typography>
                                                <Typography gutterBottom component="h2" className={classes.timelineDate}>
                                                    <Box fontStyle="italic">
                                                        De septembre 2019 à août 2021 - 2 ans
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{width:"40%", height:"40%", paddingTop:"10px"}}>
                                            <a href="https://www.fonroche-eclairagesolaire.fr/" target="bank">
                                                <img width="100%" src={process.env.PUBLIC_URL + "/logoFonroche.jpg"} />
                                            </a>
                                        </Grid>
                                        <Typography variant="body2" align="justify">
                                            <b>Poste : </b> Apprentie développeuse informatique.
                                            <br />
                                            <br />
                                            Deux années durant lesquelles j'ai développé et maintenu des applications.
                                            J'ai travaillé sur le projet d'une application mobile à destination des clients de l'entreprise. 
                                            Ainsi que sur la maintenance et le développement de nouvelles versions de l'application de suivi de production.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineOppositeContent />
                        <TimelineSeparator>
                            <TimelineDot className={classes.secondaryTail}>
                                {/* <LaptopMacIcon /> */}
                                <BusinessCenterIcon />
                            </TimelineDot>
                            <TimelineConnector className={classes.secondaryTail} />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                                <Grid container spacing={2} style={{paddingBottom:"5px"}}>
                                    <Grid item xs={12} sm container>
                                        <Grid item style={{width:"40%", height:"40%", paddingTop:"10px"}}>
                                            <a href="https://www.fonroche-eclairagesolaire.fr/" target="bank">
                                                <img width="100%" src={process.env.PUBLIC_URL + "/logoFonroche.jpg"} />
                                            </a>
                                        </Grid>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography className={classes.timelineTitle} variant="h6" component="h1">
                                                    <Box fontWeight="fontWeightBold">
                                                        Stage à Fonroche Lighting
                                                    </Box>
                                                </Typography>
                                                <Typography gutterBottom component="h2" className={classes.timelineDate}>
                                                    <Box fontStyle="italic">
                                                        De février 2019 à août 2019 - 6 mois
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography variant="body2" align="justify">
                                            <b>Poste : </b> Développeuse informatique stagiaire.
                                            <br />
                                            <br />
                                            Lors de mon stage, j'ai développé une application de suivi de production en PHP. Au terme duquel j'ai obtenu ma licence en 
                                            ingénierie du logiciel.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot className={classes.secondaryTailLight}>
                                <SchoolIcon />
                            </TimelineDot>
                            <TimelineConnector className={classes.secondaryTailLight} />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={3} className={classes.paper}>
                                <Grid container spacing={2} style={{paddingBottom:"5px"}}>
                                    <Grid item xs={12} sm container>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item xs>
                                                <Typography className={classes.timelineTitle} variant="h6" component="h1">
                                                    <Box fontWeight="fontWeightBold">
                                                        École INTECH du groupe AEN
                                                    </Box>
                                                </Typography>
                                                <Typography gutterBottom component="h2" className={classes.timelineDate}>
                                                    <Box fontStyle="italic">
                                                        De septembre 2016 à août 2021 - 5 ans
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{width:"25%", height:"40%", paddingTop:"10px"}}>
                                            <a href="https://www.intechinfo.fr/" target="bank" >
                                                <img width="100%" src={process.env.PUBLIC_URL + "/intech.png"} />
                                            </a>
                                        </Grid>
                                        <Typography variant="body2" align="justify">
                                            Étudiante à INTECH pour préparer une licence et un master en ingénierie du logiciel.
                                            <br />
                                            INTECH est une École Supérieure d’Ingénierie Informatique créée en 2002. Association à but non lucratif (loi 1901), 
                                            INTECH est rattachée au Groupe d'enseignement supérieur et de recherche ESIEA.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Container>
        </div>
    );
}