import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Divider, Grid, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '35%',
      /* filter: "blur(1px)", */
    },
    cardContent: {
      flexGrow: 1,
    },
    subtitle: {
        padding: theme.spacing(3, 0, 0, 0),
    },
    divider: {
        margin: theme.spacing(2, 2, 2, 2),
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function AppliDeSuivi() {
    const classes = useStyles();
    useEffect(() => {
        document.title = 'Application de suivi de production';
        window.scrollTo(0, 0);
    });
    return (
        <div className={classes.heroContent}>
            <Container maxWidth="lg">
                <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                    Application de suivi de production
                </Typography>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item className={classes.cardGrid} xs>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cardMedia}
                                image={process.env.PUBLIC_URL + "/appliDeSuivi2.jpg"}
                                title="Application de suivi de production"
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Présentation
                                    </Box>
                                </Typography>
                                <Typography> 
                                    Projet réalisé lors de mon stage à Fonroche Lighting. 
                                    Ma mission a été de développer une application industrielle de suivi de production 
                                    permettant d'effectuer de la traçabilité sur les produits et les composants.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Contexte
                                    </Box>
                                </Typography>
                                <Typography align="justify">
                                    L'entreprise Fonroche Lighting possédait un logiciel, développé en interne, utilisé pour la production 
                                    de lampadaires. Ce logiciel comprenait plusieurs fonctionnalités qui permettent de faire un suivi 
                                    et de la gestion de production. Un opérateur pouvait assembler un produit, visualiser les composants
                                    qui constituent ce produit, remplacer un composant d’un produit, démonter un
                                    produit et transférer un produit d’un client vers un autre client.
                                    <br />
                                    Cependant, ce logiciel ne fonctionnait pas correctement puisque lors de certaines
                                    manipulations des données pouvaient alors être perdues. De plus, il ne permettait pas de chercher des
                                    informations via l’interface. Si un utilisateur cherchait une information précise, il devait alors le
                                    faire directement dans la base de données.
                                    <br />
                                    L’utilisation de ce logiciel était très fastidieuse, limitée et pas intuitive. De plus, l’entreprise
                                    n’était pas en possession des codes sources, ni de documentations et la base de données utilisée n'est pas
                                    adaptée aux besoins de l’application.
                                    <br />
                                    <br />
                                    Mes objectifs étaient de faire une application web qui soit simple et ergonomique à utiliser.
                                    De créer une nouvelle base de données qui permettrait de faire de la traçabilité des produits et 
                                    des composants.
                                    <br />
                                    <br />
                                    Ce projet a débuté peu de temps après la concrétisation du contrat de Fonroche Lighting pour 
                                    l'installation de 50 000 lampadaires au Sénégal.
                                    L’application et la base de données que je devais développer devaient remplacer le plus rapidement 
                                    possible l'outil actuel afin de permettre de commencer ce grand projet sur une meilleure base.
                                    <br />
                                    Cependant, si mon application de suivi de production n'était pas disponible alors la fabrication des 
                                    lampadaires aurait été faite sur l'ancien logiciel, où des pertes de données pouvaient avoir lieu. De plus, 
                                    si mon application avait contenu des bogues cela aurait aussi entraîné des pertes de données. 
                                    Et s'il y a des pertes de données, cela aurait empêché de faire de la traçabilité sur les produits.
                                    {/* RISQUE : OUTIL DE PRODUCT DONT SI MARCHE PAS -> ANCIEN OUTIL (AVEC SON RISQUE DE PERTE DONNEES) 
                                    SI BUG -> PERTE DE DONNEES DONC PAS TRACA */}
                                    {/* Mon application ainsi que la nouvelle base données aurait permit de partir sur des meilleurs bases 
                                    pour un si grand projet. */}
                                     
                                    {/* (Enjeux et risques) */}

                                    {/* L'entreprise utilisait un logiciel de suivi de production et de gestion des stocks qui a 
                                    été développer en interne. Suite à plusieurs années d'utilisation, trois constats ont pu être fait. 
                                    Premièrement, l'application n'était pas intuitive pour les utilisateurs. 
                                    Deuxièmement, la personne ayant développé l'application a quitté l'entreprise sans avoir 
                                    produit une documentation de son travail. 
                                    Et pour finir, lors de certaines utilisation du logiciel des données sont supprimées de la base de données. */}
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Déroulé
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    {/* Dans un premier temps, je suis allé sur le site de production de lampadaires pour <strong>intéragir 
                                    avec les opérateurs et la cheffe d'atelier</strong> pour comprendre les difficultés qu'ils rencontraient 
                                    avec la version actuelle du logiciel, les attentes ainsi que leurs besoins.
                                    Suite à cette première étape, je me suis procuré le logiciel et je l'ai expérimenté. Ceci m'a permit de 
                                    comprendre son fonctionnement et ses inconvénients.
                                    <br />
                                    <br /> */}
                                    Dans un premier temps, j'ai passé un peu de temps à la
                                    production pour bien comprendre le fonctionnement de la ligne de production ainsi
                                    que l’utilisation du logiciel. Pendant ces visites, j'ai pu poser des
                                    questions sur le logiciel, les fonctionnalités qui posaient des problèmes aux opérateurs
                                    et celles qu'ils souhaitaient avoir pour faciliter la détection des erreurs.
                                    <br />
                                    <br />
                                    Suite aux différentes visites à la production, j’ai eu accès au logiciel, à sa base
                                    de données et au manuel utilisateur. Cela m’a permis de m’imprégner plus facilement
                                    des contraintes liées l’utilisation de ce logiciel et de ses pertes de données donc des
                                    problèmes rencontrés les utilisateurs.
                                    <br />
                                    <br />
                                    Avec mon utilisation du logiciel et les retours des opérateurs en production,
                                    j’ai pu observer que l’outil ne permettait pas de retrouver facilement les erreurs lors
                                    d’un assemblage ni de corriger cette erreur.
                                    <br />
                                    <br />
                                    Il a été décidé de développer l'application web en 
                                        {" "}
                                        <Link className={classes.link} to="/php">PHP</Link>
                                        {" "}
                                    pour que celle-ci puisse être accessible de partout et dépoyable facilement.
                                    <br />
                                    Pour que l'application que je développais remplace le plus rapidement possible le logiciel et pour 
                                    régler les inconvénients vus ci-dessus, j’ai commencé par faire les fonctionnalités les plus
                                    importantes, c’est-à-dire la création et la modification d’un assemblage ainsi qu'un historique 
                                    des produits fabriqués.
                                    <br />
                                    <br />
                                </Typography>
                                <Grid alignItems="center"  justify="center" container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/appliSuiviAssemblage.png"} alt="Application de suivi de production - Création d'un produit" width="100%" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <img src={process.env.PUBLIC_URL + "/appliSuiviSearch2.png"} alt="Application de suivi de production - Recherche" width="100%" />
                                    </Grid>
                                </Grid>
                                <div align="center">
                                    <Typography variant="caption">Photos de l'application de suivi de production</Typography>
                                </div>
                                <Typography>
                                    <br />
                                    <br />
                                    Pour permettre aux utilisateurs de l’application de faire de la traçabilité, j’ai
                                    développé une fonctionnalité de recherche. Celle-ci permet de retrouver des informations à partir d'un 
                                    numéro de série d'un produit fini ou d'un composant. De plus, l'utilisateur peut faire une recherche 
                                    avancée en renseignant un critère par exemple un fournisseur, une commande ou une date. J'ai donc 
                                    passé beaucoup de temps sur cette fonctionnalité pour écrire et optimiser les 
                                        {" "}
                                        <Link className={classes.link} to="/sql">requêtes SQL</Link>
                                        .
                                    Pour cette partie, j'ai dû beaucoup 
                                        {" "}
                                        <Link className={classes.link} to="/communication">interagir avec la cheffe d'atelier 
                                        ainsi que le SAV et les opérations</Link>
                                        {" "}
                                    pour que cette fonctionnalité corresponde au mieux à leurs besoins.
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Résultat
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    Voilà deux ans que mon application est utilisée pour la production de lampadaires. Plus de 80 000 produits 
                                    ont été renseigné via mon application. Celle-ci est utilisée sur 3 continents.
                                    <br />
                                    Tout le long de mon alternance, j'ai fait évolué l'application suite aux retours des différents 
                                    utilisateurs que cela soit les opérateurs, le SAV ou les opérations.
                                    <br />
                                    Cependant, je me rends bien compte que plus le temps passe, plus les besoins des utilisateurs évoluent
                                    et ne correspondent plus à certaines fonctionnalités qui ont été développées.
                                    {/* Cependant, je me rends bien compte que plus le temps passe, plus on stocks de nouvelles données plus certaines fonctionnalités ne correspondent plus aux attentes des utilisateurs. */}
                                </Typography>
                                <Typography gutterBottom className={classes.subtitle} variant="h5" component="h2">
                                    <Box fontStyle="italic" fontWeight="fontWeightBold">
                                        Conclusion
                                    </Box>
                                </Typography>
                                <Typography align="justify"> 
                                    J'ai pris un réel plaisir à faire cette application et à la faire évoluer. De plus, cette première 
                                    expérience dans l'entreprise m'a permis de m'imprégnier du monde industriel avec ces enjeux et de ces 
                                    exigences. C'est très gratifiant que mon application soit utilisée et est devenue un logiciel indispensable 
                                    pour l'entreprise.
                                </Typography>
                            </CardContent>
                            <Divider variant="middle" className={classes.divider} />
                            <Typography gutterBottom className={classes.divider} variant="h6" >
                                <Box fontStyle="italic" >
                                    Articles en lien :
                                </Box>
                            </Typography>
                            <CardActions>
                                <Chip component={Link} to="/php" clickable color="primary" label="PHP" variant="outlined" />
                                <Chip component={Link} to="/sql" clickable color="primary" label="SQL" variant="outlined" />
                                <Chip component={Link} to="/communication" clickable color="primary" label="Communication" variant="outlined" />
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Container>
        </div>
    );
}