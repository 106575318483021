import React from "react";
import {
  Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailIcon from '@material-ui/icons/Mail';
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    icon: {
        marginLeft: "0px",
    },
    link: {
        color: "#007bff",
        '&:hover': {
            color: "#0056b3",
        }
    },
}));

export default function Footer() {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <Container maxWidth="sm">
                <Typography variant="h6" align="center" gutterBottom>
                    Coordonnées
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    Vous pouvez me contacter sur 
                    <IconButton edge="start" className={classes.icon} href="https://fr.linkedin.com/in/estelle-boudon-425849151" target="_blank" aria-label="menu">
                        <LinkedInIcon />
                    </IconButton>
                    ou par 
                        <IconButton edge="start" className={classes.icon} href="mailto:estelle.boudon19@gmail.com" aria-label="menu">
                            <MailIcon />
                        </IconButton>
                    {/* Retrouvez-moi sur <LinkedInIcon /> ou contactez-moi par <MailIcon fontSize="medium" /> */}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                    {'Copyright © '}
                    <Link className={classes.link} to="/">
                        Estelle BOUDON
                    </Link>
                    {' ~ '}
                    <Link className={classes.link} to="/mentionsLegales">
                        Mentions légales
                    </Link>
                    {' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Container>
        </footer>
    );
}